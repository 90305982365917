import { createAction, ActionType } from 'typesafe-actions';
import { AssetCategory } from 'Models/AssetCategoryModels';
import { Institute } from 'Models/InstituteModels';
import { Location } from 'Models/LocationModels';
import { AgrilifeAgency } from 'Models/AgencyModels';
import { EmployeeSummary } from 'Models/EmployeeModels';
import { FundSource } from 'Models/FundSourceModels';

interface Dropdowns {
	categories: AssetCategory[];
	locations: Location[];
	institutes: Institute[];
	agencies: AgrilifeAgency[];
	employees: EmployeeSummary[];
	fundSources: FundSource[];
}

// actions
const Actions = {
	DropdownsLoaded: createAction('DROPDOWNS_LOADED')<Dropdowns>(),
};

export class AddAssetState implements Dropdowns {
	isLoading = true;
	categories: AssetCategory[] = [];
	locations: Location[] = [];
	institutes: Institute[] = [];
	agencies: AgrilifeAgency[] = [];
	employees: EmployeeSummary[] = [];
	fundSources: FundSource[] = [];
}

export const AddAssetReducer = (state: AddAssetState, action: ActionType<typeof Actions>): AddAssetState => {
	switch (action.type) {
		case 'DROPDOWNS_LOADED':
			return { ...state, ...action.payload, isLoading: false };

		default:
			return state;
	}
};
