import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import { uppercaseFirst } from 'Common/Helpers/TextHelper';

export interface IDeleteConfirmProps {
	/** The name of the item to be deleted (i.e. Obligation, Partner, etc.) */
	label: string;
	additionalWarning?: string;
	/** A boolean value that determines if the modal is open or not */
	isOpen: boolean;
	/** Should be function in the calling component that will handle the delete */
	onConfirm: () => void;
	/** Passes onClose function value from the calling component */
	onCancel: () => void;
}

export const DeleteConfirmation = (props: IDeleteConfirmProps) => {
	return (
		<Dialog maxWidth="xs" open={props.isOpen} onClose={() => props.onCancel()} aria-labelledby="form-dialog-title">
			<DialogTitle id="form-dialog-title">Delete {uppercaseFirst(props.label)}</DialogTitle>
			<DialogContent>
				Are you sure you want to delete this {props.label.toLowerCase()}? {props.additionalWarning || ''}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.onCancel} variant="text">
					Cancel
				</Button>
				<Button onClick={props.onConfirm}>Delete</Button>
			</DialogActions>
		</Dialog>
	);
};
