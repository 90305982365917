export class Note {
	id = 0;
	text = '';
	submittedAt: Date = new Date();
	createdBy = '';
}

export class AddNote {
	text = '';
	assetId: number;

	constructor(assetId: number) {
		this.assetId = assetId;
	}
}
