import React, { useState, createRef, useCallback, useEffect } from 'react';
import { FileModels } from 'Models';
import {
	makeStyles,
	Grid,
	TextField,
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Typography,
	MenuItem,
	Select,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
} from '@material-ui/core';
import { GetApp as GetAppIcon, Delete as DeleteIcon } from '@material-ui/icons';
import { DateHelper } from 'Common/Helpers';
import Dropzone, { DropzoneRef } from 'react-dropzone';
import ClearIcon from '@material-ui/icons/Clear';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useFilesEndpoint } from 'Endpoints';
import { FileCategory } from 'Models/FileModels';
import { useAppState } from 'Context/AppProvider';
import { ProtectedComponent } from 'Common/Utilities';
import classNames from 'classnames';

export interface IFilesTabContentProps {
	assetId: number;
	files: FileModels.File[];
	isEditing: boolean;
}

enum FileModalOptions {
	None = 0,
	Upload = 1,
	Edit = 2,
	Delete = 3,
}
const useStyles = makeStyles(theme => ({
	listItem: {
		padding: 0,
	},
	right: {
		right: '0px !important',
	},
	alignRight: {
		paddingTop: '5px',
		textAlign: 'right',
	},
	dropzoneRoot: {
		minHeight: 200,
		minWidth: 400,
	},
	dropzoneDrop: {
		border: '#ccc 4px dashed',
		borderRadius: 5,
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		marginTop: theme.spacing(2),
		textAlign: 'center',
	},
	buttonIcon: {
		marginRight: theme.spacing(1),
	},
	uploadIcon: {
		fontSize: '2rem',
	},
	delete: { color: '#FF0000' },
}));

export const FilesTabContent = (props: IFilesTabContentProps) => {
	const dropzoneRef = createRef<DropzoneRef>();
	const classes = useStyles();
	const [searchValue, setSearchValue] = useState('');

	const ep = useFilesEndpoint();
	const userEp = useAppState();
	const [currentUser, setCurrentUser] = useState('');
	const [fileCategories, setFileCategories] = useState<FileCategory[]>([]);
	const [filesForUpload, setFilesForUpload] = useState<FileModels.AddFile[]>([]);
	const [files, setFiles] = useState<FileModels.File[]>(props.files);
	const [fileToDelete, setFileToDelete] = useState<FileModels.File>(new FileModels.File());
	const [openModal, setOpenModal] = useState<FileModalOptions>(FileModalOptions.None);
	const closeModal = () => setOpenModal(FileModalOptions.None);

	useEffect(() => {
		ep.Categories().then(r => setFileCategories(r));
		userEp.CurrentUser && setCurrentUser(userEp.CurrentUser.preferredUsername);
	}, []);

	const handleDeleteClick = (fileId: number) => () => {
		const file = props.files.find(x => x.id === fileId);

		if (file === undefined) {
			return;
		}

		setFileToDelete(file);
		setOpenModal(FileModalOptions.Delete);
	};

	const onFileDeleteConfirm = async () => {
		const deletedId = await ep.Delete(fileToDelete.id);
		if (deletedId === fileToDelete.id) {
			setFiles(files.filter(f => f.id !== deletedId));
		}
		setFileToDelete(new FileModels.File());
		closeModal();
	};

	const onFileDeleteCancel = () => {
		setFileToDelete(new FileModels.File());
		closeModal();
	};

	// #region File Upload

	const dropzoneAccept = [
		'image/jpeg',
		'image/png',
		'image/gif',
		'text/plain',
		'text/csv',
		'application/pdf',
		'application/msword',
		'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		'application/zip',
		'application/vnd.ms-excel',
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	];

	const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
		const reader = new FileReader();
		return new Promise<ArrayBuffer>((resolve, reject) => {
			reader.onerror = () => {
				reader.abort();
				reject(new DOMException('Problem parsing input file.'));
			};
			reader.onload = () => {
				const result = reader.result;
				if (result !== null) {
					resolve(result as ArrayBuffer);
				}
				reject(new DOMException('File contents are empty'));
			};
			reader.readAsArrayBuffer(file);
		});
	};

	const onFileDrop = useCallback(
		async (acceptedFiles: File[]) => {
			const forUpload: FileModels.AddFile[] = [];
			const uncategorized = fileCategories.find(x => x.name === 'Uncategorized');
			let defaultCategoryId = 0;
			if (uncategorized !== undefined) defaultCategoryId = uncategorized.id;

			for (const file of acceptedFiles) {
				const buffer = await readFileAsArrayBuffer(file);
				const content: Blob = new Blob([buffer]);
				const suffixIndex = file.name.lastIndexOf('.');
				const fileTitle = file.name.substring(0, suffixIndex);

				forUpload.push(
					new FileModels.AddFile({
						assetId: props.assetId,
						fileCategoryId: defaultCategoryId,
						username: currentUser,
						url: file.name,
						title: fileTitle,
						contents: content,
					})
				);
			}

			setFilesForUpload(filesForUpload.concat(forUpload));
		},
		[filesForUpload, fileCategories]
	);

	const cancelUpload = () => {
		closeModal();
		setFilesForUpload([]);
	};

	const uploadFiles = () => {
		for (const file of filesForUpload) {
			ep.Add(file.toFormData()).then(() => handleUploadSuccess());
		}
	};

	const handleUploadSuccess = () => {
		closeModal();
		setFilesForUpload([]);
	};

	// #endregion

	return (
		<Grid container={true}>
			<Grid item={true} xs={12} className={classes.alignRight}>
				<TextField
					value={searchValue}
					placeholder="search file name"
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
				/>

				<ProtectedComponent allowedRoles={['Admin']}>
					<Button
						variant="contained"
						color="primary"
						size="small"
						aria-label="upload file"
						onClick={() => setOpenModal(FileModalOptions.Upload)}
					>
						<CloudUploadIcon className={classes.buttonIcon} /> Upload
					</Button>
				</ProtectedComponent>
			</Grid>
			<Grid container={true} item={true} direction="column" xs={12}>
				<List dense={true}>
					{files
						.filter(f => f.title.includes(searchValue))
						// sort by id instead of date since multiple files
						// with the same timestamp continually reverse order
						.sort((a, b) => b.id - a.id)
						.map(file => {
							const uploadedTime = file.submittedAt
								? DateHelper.timeSince(new Date(file.submittedAt))
								: 'unknown';
							return (
								<ListItem key={`file-${file.id}`} className={classes.listItem}>
									<ListItemText
										primary={file.title}
										secondary={`${file.fileCategoryName}: uploaded ${uploadedTime}`}
									/>

									<ListItemSecondaryAction className={classes.right}>
										<IconButton
											edge="end"
											size="small"
											aria-label="download-file"
											onClick={() => ep.Download(file.id)}
										>
											<GetAppIcon />
										</IconButton>
										<IconButton
											edge="end"
											size="small"
											aria-label="delete-file"
											onClick={handleDeleteClick(file.id)}
										>
											<DeleteIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
						})}
				</List>
			</Grid>
			<Dialog
				maxWidth="md"
				open={openModal === FileModalOptions.Upload}
				onClose={closeModal}
				aria-labelledby="upload-form-dialog-title"
			>
				<DialogTitle id="upload-form-dialog-title">Upload Files</DialogTitle>
				<DialogContent>
					<Grid item={true} sm={12}>
						<Dropzone
							ref={dropzoneRef}
							onDrop={onFileDrop}
							accept={dropzoneAccept}
							noClick={filesForUpload.length > 0}
						>
							{({ getRootProps, getInputProps }) => (
								<div
									{...getRootProps({
										className: classNames(classes.dropzoneRoot, classes.dropzoneDrop),
									})}
								>
									<input {...getInputProps()} />
									<Typography variant="h6">Drag and drop files here</Typography>
									<Typography variant="h6" color="textSecondary">
										(or click to select files)
									</Typography>
									<CloudUploadIcon color="secondary" className={classes.uploadIcon} />
								</div>
							)}
						</Dropzone>
						<div>
							{filesForUpload.length > 0 ? (
								<Grid container={true} item={true} xs={12}>
									{filesForUpload.map(file => (
										<Grid
											key={`${file.url}-uploadform`}
											container={true}
											justify="space-between"
											item={true}
											xs={12}
										>
											<Grid item={true}>
												<Typography variant="body1">{file.url}</Typography>
											</Grid>
											<Grid item={true}>
												<Select
													value={file.fileCategoryId}
													onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
														const newValues = new FileModels.AddFile({
															...file,
															fileCategoryId: parseInt(event.target.value, 10),
														});
														const index = filesForUpload.map(x => x.url).indexOf(file.url);
														const newArray = [...filesForUpload];
														newArray.splice(index, 1, newValues);
														setFilesForUpload(newArray);
													}}
													placeholder="File Category"
												>
													{fileCategories.map(cat => (
														<MenuItem key={`filecat-${cat.id}`} value={cat.id}>
															{cat.name}
														</MenuItem>
													))}
												</Select>
												<IconButton
													onClick={() => {
														setFilesForUpload([
															...filesForUpload.filter(x => x.url !== file.url),
														]);
													}}
												>
													<ClearIcon className={classes.delete} />
												</IconButton>
											</Grid>
										</Grid>
									))}
								</Grid>
							) : (
								''
							)}
						</div>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button color="default" onClick={cancelUpload}>
						Cancel
					</Button>
					<Button color="primary" onClick={uploadFiles}>
						Upload
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog
				maxWidth="xs"
				open={openModal === FileModalOptions.Delete}
				onClose={onFileDeleteCancel}
				aria-labelledby="delete-form-dialog-title"
			>
				<DialogTitle id="delete-form-dialog-title">Delete File</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to delete <strong>{fileToDelete.title}</strong>?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onFileDeleteCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={onFileDeleteConfirm} color="primary">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};
