/* tslint:disable: no-import-side-effect */
import 'typeface-roboto-condensed';
import 'Layout/Assets/global.css';
/* tslint:enable: no-import-side-effect */

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from 'Layout';
import { AppProvider } from 'Context/AppProvider';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from 'Common/Auth/config';

import * as Sentry from '@sentry/browser';
import settings from 'settings';

Sentry.init({ dsn: settings.sentry.dsn, environment: settings.sentry.environment });

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
	<AppProvider>
		<MsalProvider instance={msalInstance}>
			<App />
		</MsalProvider>
	</AppProvider>,
	document.getElementById('app')
);

declare let module: any;
if (module.hot) {
	module.hot.accept();
}
