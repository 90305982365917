import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { DOMHelper, ReactHelper } from 'Common/Helpers';
import { useEmployeesEndpoint, useInstitutesEndpoint } from 'Endpoints';
import { EmployeeModels, InstituteModels } from 'Models';
import { Formik, Form, Field } from 'formik';
import { EmployeeSchemas } from 'Employees/Forms';
import { StyledCard, EnhancedButton } from 'Common/Elements';
import { Fade, Grid, FormControl, LinearProgress, makeStyles } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { FormDebugging, InstituteSelect } from 'Common/FormFields';

const useStyles = makeStyles(() => ({
	fieldContainer: {
		marginBottom: 10,
	},
}));

export const Add = (props: RouteComponentProps<any>) => {
	const employeeEndpoint = useEmployeesEndpoint();
	const instituteEndpoint = useInstitutesEndpoint();

	const classes = useStyles();
	const nameof = ReactHelper.useNameof<EmployeeModels.AddEmployee>();
	const [institutes, setInstitutes] = useState<InstituteModels.Institute[]>([new InstituteModels.Institute()]);

	useEffect(() => {
		DOMHelper.setPageTitle('Add Employee');
		instituteEndpoint.Get().then(r => r && setInstitutes(r));
	}, []);

	return (
		<Fade in={true}>
			<Grid container={true}>
				<Grid item={true} xs={12} md={8} lg={6} xl={4}>
					<StyledCard title="Add Employee">
						<Formik
							initialValues={new EmployeeModels.AddEmployee()}
							validationSchema={EmployeeSchemas.AddEmployeeSchema}
							onSubmit={(values, { setSubmitting }) => {
								employeeEndpoint
									.Add(values)
									.then(r => r && props.history.push(`/employees/${r}`))
									.catch(() => setSubmitting(false));
							}}
						>
							{formprops => (
								<Form>
									<Grid className={classes.fieldContainer}>
										<FormControl fullWidth={true}>
											<InstituteSelect
												institutes={institutes}
												name={nameof('instituteId')}
												label="Institute"
											/>
										</FormControl>
										<FormControl fullWidth={true}>
											<Field
												name={nameof('firstName')}
												type="text"
												label="First Name"
												multiline={true}
												component={TextField}
											/>
										</FormControl>
										<FormControl fullWidth={true}>
											<Field
												name={nameof('lastName')}
												type="text"
												label="Last Name"
												multiline={true}
												component={TextField}
											/>
										</FormControl>
									</Grid>
									{formprops.isSubmitting && <LinearProgress />}
									<EnhancedButton
										submit={true}
										disabled={employeeEndpoint.IsLoading}
										isSubmitting={formprops.isSubmitting}
										action="add"
										label="Employee"
									/>
									<FormDebugging />
								</Form>
							)}
						</Formik>
					</StyledCard>
				</Grid>
			</Grid>
		</Fade>
	);
};
