import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export const NotAuthorized = () => {
	return (
		<Grid container={true}>
			<Grid item={true} sm={3} />
			<Grid item={true} sm={6}>
				<Typography variant="h1" component="h2" color="error">
					403 Not Authorized
				</Typography>
				<Typography variant="body2">Not this time, pal</Typography>
			</Grid>
		</Grid>
	);
};
