import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';
import { EmployeeSummary, EmployeeDetails, AddEmployee } from 'Models/EmployeeModels';

export const useEmployeesEndpoint = () => {
	const { useError, useSuccess } = useNotifications();
	const ep = Endpoint('employees');

	const GetEmployees = () =>
		ep.Get<EmployeeSummary[]>().catch(() => {
			useError('Error getting employees');
			return [] as EmployeeSummary[];
		});

	const Get = (id: number) =>
		ep.Get<EmployeeDetails>(id).catch(() => {
			useError('Error getting employee');
			return undefined;
		});

	const Add = (dto: AddEmployee) =>
		ep
			.Post<EmployeeSummary>(dto)
			.then(r => {
				useSuccess('Employee added');
				return r;
			})
			.catch(() => {
				useError('Error adding employee');
				return undefined;
			});

	const Delete = (id: number) =>
		ep.Delete<number>(id)
			.then(r => {
				useSuccess("Employee deleted");
				return r;
			}).catch(() => {
				useError('Error deleting employee');
				return undefined;
			});

	return { GetEmployees, Get, Add, Delete, IsLoading: ep.IsLoading };
};
