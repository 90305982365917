import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { DOMHelper } from 'Common/Helpers';
import { useEmployeesEndpoint } from 'Endpoints';
import { EmployeeDetails } from 'Models/EmployeeModels';
import { Grid, LinearProgress, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { PageHeader, StyledCard } from 'Common/Elements';
import { AssetTable } from 'Common/Tables';

export const Details = (props: RouteComponentProps<any>) => {
	const ep = useEmployeesEndpoint();
	const [employee, setEmployee] = useState<EmployeeDetails>();

	useEffect(() => {
		DOMHelper.setPageTitle('Employee Details');
		ep.Get(props.match.params.id).then(r => r && setEmployee(r));
	}, []);

	if (!employee) {
		return <LinearProgress />;
	}

	return (
		<React.Fragment>
			<PageHeader text={`${employee.firstName} ${employee.lastName}`} />
			<Grid container={true}>
				<Grid item={true} md={4}>
					<StyledCard title="">
						<Table>
							<TableBody>
								<TableRow>
									<TableCell variant="head">First Name</TableCell>
									<TableCell>{employee.firstName}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Last Name</TableCell>
									<TableCell>{employee.lastName}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell variant="head">Institute</TableCell>
									<TableCell>{employee.instituteName}</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</StyledCard>
				</Grid>
				<Grid item={true} md={8}>
					<AssetTable isLoading={false} assets={employee.assets} />
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
