import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { Layout } from './';
import { useAppState } from 'Context/AppProvider';
import { GlobalErrorBoundary } from 'Common/Errors';

const overallTheme = createMuiTheme({
	palette: {
		primary: {
			main: '#43a047', // green
		},
		secondary: {
			main: '#55A1C1', // bluish-graying-tealish
		},
		error: {
			main: '#FB404B', // red
		},
		background: {
			default: '#eaeaea',
		},
	},
	typography: {
		fontFamily: ['Roboto Condensed', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
	},
	overrides: {
		MuiDrawer: {
			paper: {
				backgroundColor: '#222D32',
				color: '#8aa4af',
			},
			paperAnchorDockedLeft: {
				height: '100vh',
			},
		},
		MuiDivider: {
			root: {
				backgroundColor: 'rgba(255,255,255,0.3)',
			},
		},
		MuiListItemIcon: {
			root: {
				color: '#8aa4af',
			},
		},
		MuiListItemText: {
			primary: {
				fontSize: '14px',
			},
		},
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: 'white',
				color: '#333',
			},
		},
	},
});

export const darkTheme = createMuiTheme({
	...overallTheme,
	palette: {
		type: 'dark',
		primary: {
			main: '#43a047', // green
		},
		secondary: {
			main: '#55A1C1', // bluish-graying-tealish
		},
	},
	overrides: {
		...overallTheme.overrides,
		MuiAppBar: {
			colorPrimary: {
				backgroundColor: '#333',
			},
		},
	},
});

export const App = () => {
	const state = useAppState();

	return (
		<GlobalErrorBoundary>
			<BrowserRouter>
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<ThemeProvider theme={state.Theme === 'dark' ? darkTheme : overallTheme}>
						<SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
							<Layout isLoading={state.LoginStatus !== 'Logged In'} />
						</SnackbarProvider>
					</ThemeProvider>
				</MuiPickersUtilsProvider>
			</BrowserRouter>
		</GlobalErrorBoundary>
	);
};
