import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import classnames from 'classnames';
import { makeStyles, Theme, Grid, Typography, Card, CardContent, LinearProgress } from '@material-ui/core';
import { List as ListIcon, Note as NoteIcon, FileCopy as FileIcon } from '@material-ui/icons';
import { DOMHelper } from 'Common/Helpers';
import { blue, green, orange, grey } from '@material-ui/core/colors';
import { useSearchEndpoint } from 'Endpoints';
import { SearchResult } from 'Models/AppModels';

const useStyles = makeStyles((theme: Theme) => ({
	heading: {
		marginBottom: theme.spacing(2),
	},
	card: {
		marginLeft: '0',
		marginRight: '0',
		marginBottom: theme.spacing(2),
	},
	cardContent: {
		paddingBottom: `${theme.spacing(2)}px !important`,
	},
	typeIcon: {
		fontSize: '50px',
		lineHeight: '50px',
		marginRight: theme.spacing(2),
	},
	proposalIcon: {
		color: blue[400],
	},
	fileIcon: {
		color: orange[500],
	},
	noteIcon: {
		color: green[300],
	},
	cardSubheader: {
		color: grey[500],
		fontSize: '13px',
		letterSpacing: '0.5px',
		textTransform: 'uppercase',
	},
	downloadIcon: {
		fontSize: '30px',
	},
}));

export const Search = (props: RouteComponentProps<any>) => {
	const classes = useStyles();
	const ep = useSearchEndpoint();
	const term = props.match.params.term;
	const [results, setResults] = useState<SearchResult[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	const getResults = async () => {
		const result = await ep.PerformSearch(term);
		setResults(result);
		setIsLoading(false);
	};

	useEffect(() => {
		DOMHelper.setPageTitle('Search');
		getResults();
	}, []);

	if (isLoading) {
		return <LinearProgress />;
	}
	return (
		<Grid container={true}>
			<Grid item={true} lg={8}>
				<Typography component="h1" variant="h6" className={classes.heading}>
					Showing {results.length} results for the search term "{term}"
				</Typography>

				{results.map((result, index) => {
					const key = `${result.type}-${result.linkId}${index}`;
					const icon =
						result.type === 'Asset' ? (
							<ListIcon className={classnames(classes.typeIcon, classes.proposalIcon)} />
						) : result.type === 'File' ? (
							<FileIcon className={classnames(classes.typeIcon, classes.fileIcon)} />
						) : (
							<NoteIcon className={classnames(classes.typeIcon, classes.noteIcon)} />
						);
					const link = '/assets/' + result.linkId;

					return (
						<Card className={classes.card} key={key}>
							<CardContent className={classes.cardContent}>
								<Grid container={true} justify="space-between">
									<Grid item={true}>
										<Grid container={true}>
											<Grid item={true}>
												<Link to={link}>{icon}</Link>
											</Grid>
											<Grid item={true}>
												<Link to={link}>
													<Typography component="span" className={classes.cardSubheader}>
														{result.type}
													</Typography>
													<Typography variant="h6" component="h4">
														{result.name}
													</Typography>
													<Typography component="p" variant="body2" color="textSecondary">
														{result.description && result.description.substring(0, 100)}
													</Typography>
												</Link>
											</Grid>
										</Grid>
									</Grid>
									{result.type === 'File' ? (
										<Grid item={true}>
											{result.fileName}
											{/*
											TODO: Build out File download functionality
											<Tooltip title="Download" placement="top">
												<IconButton
													edge="end"
													aria-label="download-file"
													onClick={ReactHelper.downloadFile(
														props.getFileContents,
														result.fileId,
														result.url
													)}
												>
													<DownloadIcon className={classes.downloadIcon} />
												</IconButton>
											</Tooltip> */}
										</Grid>
									) : (
										''
									)}
								</Grid>
							</CardContent>
						</Card>
					);
				})}
			</Grid>
		</Grid>
	);
};

export default Search;
