import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';
import { SearchResult } from 'Models/AppModels';

export const useSearchEndpoint = () => {
	const { useError, useSuccess } = useNotifications();
	const ep = Endpoint('search');

	const PerformSearch = (term: string) =>
		ep.Get<SearchResult[]>(term).catch(() => {
			useError('Error performing search');
			return [] as SearchResult[];
		});

	const BuildIndex = () =>
		ep
			.Post<string>(null, 'build')
			.then(r => {
				useSuccess('Index built');
				return r;
			})
			.catch(() => {
				useError('Error building index');
				return undefined;
			});

	return { PerformSearch, BuildIndex, IsLoading: ep.IsLoading };
};
