import React, { useEffect, useReducer } from 'react';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { AssetModels } from 'Models';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { EnhancedButton } from 'Common/Elements';
import { LinearProgress, FormControl, Grid } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router';

import { AddAssetSchema } from './AssetSchemas';
import {
	useAssetsEndpoint,
	useAssetCategoriesEndpoint,
	useLocationsEndpoint,
	useAgenciesEndpoint,
	useInstitutesEndpoint,
	useEmployeesEndpoint,
	useFundSourcesEndpoint,
} from 'Endpoints';
import { AddAssetState, AddAssetReducer } from './AddAssetReducer';
import { FundSourceValue } from 'Models/FundSourceModels';
import {
	GlobalDatePicker,
	CategorySelect,
	AgencySelect,
	InstituteSelect,
	EmployeeSelect,
	LocationSelect,
	FundSourceSelect,
	FormDebugging,
} from 'Common/FormFields';

export const AddAssetForm = (props: RouteComponentProps<any>) => {
	const nameof = useNameof<AssetModels.AddAsset>();

	const [state, dispatch] = useReducer(AddAssetReducer, new AddAssetState());

	const ep = useAssetsEndpoint();
	const categoryEp = useAssetCategoriesEndpoint();
	const locationEp = useLocationsEndpoint();
	const instituteEp = useInstitutesEndpoint();
	const agencyEp = useAgenciesEndpoint();
	const employeeEp = useEmployeesEndpoint();
	const fundSourceEp = useFundSourcesEndpoint();

	useEffect(() => {
		// TS doesn't seem to do well with Promise.all if return types aren't specified
		// in this case, it wanted to make all three promises Proposal[]
		Promise.all([
			categoryEp.Categories(),
			locationEp.Get(),
			instituteEp.Get(),
			agencyEp.Get(),
			employeeEp.GetEmployees(),
			fundSourceEp.Get(),
		]).then(r => {
			dispatch({
				type: 'DROPDOWNS_LOADED',
				payload: {
					categories: r[0],
					locations: r[1],
					institutes: r[2],
					agencies: r[3],
					employees: r[4],
					fundSources: r[5],
				},
			});
		});
	}, []);

	return (
		<Formik
			initialValues={new AssetModels.AddAsset()}
			validationSchema={AddAssetSchema}
			onSubmit={(values, { setSubmitting }) => {
				ep.Add(values).then(result => result && props.history.push(`/Assets/${result.id}`));
				setSubmitting(false);
			}}
		>
			{formprops => (
				<Form>
					<Grid container={true}>
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field
									name={nameof('assetNumber')}
									type="text"
									label="Asset Number"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field name={nameof('name')} type="text" label="Asset Name" component={TextField} />
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field name={nameof('make')} type="text" label="Make" component={TextField} />
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field name={nameof('model')} type="text" label="Model" component={TextField} />
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field
									name={nameof('serialNumber')}
									type="text"
									label="Serial Number"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field
									component={GlobalDatePicker}
									name={nameof('purchaseDate')}
									label="Purchased On"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field name={nameof('vendor')} type="text" label="Vendor" component={TextField} />
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<CategorySelect
									categories={state.categories}
									name={nameof('categoryId')}
									label="Asset Category"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<AgencySelect
									agencies={state.agencies}
									name={nameof('agrilifeAgencyId')}
									label="Agrilife Agency"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<InstituteSelect
									institutes={state.institutes}
									name={nameof('instituteId')}
									label="Institute"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<EmployeeSelect
									employees={state.employees}
									name={nameof('employeeId')}
									label="Assigned To"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<LocationSelect
									locations={state.locations}
									name={nameof('locationId')}
									label="Location"
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<Field
									name={nameof('locationDetails')}
									type="text"
									label="Location Details"
									component={TextField}
								/>
							</FormControl>
						</Grid>
						<Grid item={true} md={1} />
						<Grid item={true} xs={12} md={5}>
							<FormControl fullWidth={true}>
								<FundSourceSelect fundSources={state.fundSources} name={nameof('fundSourceId')} />
							</FormControl>
						</Grid>
						{formprops.values.fundSourceId === FundSourceValue.SRS && (
							<>
								<Grid item={true} md={1} />
								<Grid item={true} xs={12} md={5}>
									<FormControl fullWidth={true}>
										<Field
											name={nameof('accountNumber')}
											type="text"
											label="Account Number"
											component={TextField}
										/>
									</FormControl>
								</Grid>
							</>
						)}
					</Grid>
					{formprops.isSubmitting && <LinearProgress />}
					<EnhancedButton
						submit={true}
						disabled={state.isLoading}
						isSubmitting={formprops.isSubmitting}
						action="add"
						label="Asset"
					/>
					<FormDebugging />
				</Form>
			)}
		</Formik>
	);
};

export default withRouter(AddAssetForm);
