import { FormikConsumer } from 'formik';
import React from 'react';
import settings from 'settings';

export const FormDebugging = () => {
	if (!settings.debugForms) {
		return null;
	}

	return <FormikConsumer>{formik => <pre>{JSON.stringify(formik, null, 2)}</pre>}</FormikConsumer>;
};
