export class File {
	id = 0;
	title = '';
	fileCategoryName = '';
	url = '';
	submittedAt: Date = new Date();
	createdBy = '';
}

export class AddFile {
	assetId = 0;
	fileCategoryId = 0;
	username = '';
	url = '';
	title = '';
	contents?: Blob;

	public constructor(init?: Partial<AddFile>) {
		Object.assign(this, init);
	}

	toFormData() {
		const formData = new FormData();

		formData.append('url', this.url);
		formData.append('title', this.title);
		formData.append('assetId', this.assetId.toString());
		formData.append('fileCategoryId', this.fileCategoryId.toString());
		formData.append('username', this.username);
		this.contents !== undefined && formData.append('contents', this.contents, this.url);

		return formData;
	}
}

export class FileCategory {
	id = 0;
	name = '';
	active?: boolean;
}
