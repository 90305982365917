import { AssetSummary } from './AssetModels';

export class EmployeeSummary {
	id = 0;
	firstName = '';
	lastName = '';
	name = '';
	instituteName = '';
	manager = false;
	active = true;
}

export class EmployeeDetails {
	id = 0;
	firstName = '';
	lastName = '';
	instituteName = '';
	assets: AssetSummary[] = [];
}

export class AddEmployee {
	firstName = '';
	lastName = '';
	instituteId = 0;
}
