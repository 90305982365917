import React, { useEffect, useState } from 'react';
import { useSurplusEndpoint, useAssetsEndpoint } from 'Endpoints';
import { AssetSummary } from 'Models/AssetModels';
import { AssetSelect, GlobalDatePicker } from 'Common/FormFields';
import { SurplusModels } from 'Models';
import { Formik, Form, Field } from 'formik';
import { FormControl, LinearProgress } from '@material-ui/core';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { TextField } from 'formik-material-ui';
import { AddSurplusSchema } from './SuplusSchemas';
import { EnhancedButton } from 'Common/Elements';

export interface ISurplusAssetFormProps {
	onSuccess: () => void;
	id?: number;
}

export const SurplusAssetForm = (props: ISurplusAssetFormProps) => {
	const nameof = useNameof<SurplusModels.AddSurplus>();
	// had to cast to string and parse to int because it was loading initial value as a string????
	const id = props.id ? parseInt(props.id.toString()) : 0;
	const [assets, setAssets] = useState<AssetSummary[]>([]);
	const [surplus, setSurplus] = useState(new SurplusModels.AddSurplus(id));
	const assetsEp = useAssetsEndpoint();
	const ep = useSurplusEndpoint();
	useEffect(() => {
		assetsEp.CurrentAssets().then(r => setAssets(r));
	}, []);

	const handleSuccess = () => {
		setSurplus(new SurplusModels.AddSurplus(0));
		props.onSuccess();
	};
	return (
		<React.Fragment>
			<Formik
				initialValues={surplus}
				validationSchema={AddSurplusSchema}
				onSubmit={(values, { setSubmitting }) => {
					ep.Add(values).then(response => response && handleSuccess());
					setSubmitting(false);
				}}
			>
				{formprops => (
					<Form>
						<FormControl fullWidth={true}>
							<AssetSelect
								name={nameof('assetId')}
								assets={assets.filter(
									a => a.statusName !== 'Surplused' && a.statusName !== 'Transferred'
								)}
							/>
						</FormControl>
						<FormControl fullWidth={true}>
							<Field
								name={nameof('surplusReason')}
								type="text"
								label="Surplus Reason"
								component={TextField}
							/>
						</FormControl>
						<FormControl fullWidth={true}>
							<Field component={GlobalDatePicker} name={nameof('surplusDate')} label="Surplus Date" />
						</FormControl>
						{formprops.isSubmitting && <LinearProgress />}
						<EnhancedButton
							submit={true}
							disabled={ep.IsLoading}
							isSubmitting={formprops.isSubmitting}
							action="add"
							label="Surplus"
						/>
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};
