import React, { useState, useEffect } from 'react';
import { Formik, Field, Form } from 'formik';
import { TextField } from 'formik-material-ui';
import {
	AgencyModels,
	AssetModels,
	AssetCategoryModels,
	EmployeeModels,
	InstituteModels,
	LocationModels,
	FundSourceModels,
} from 'Models';
import {
	useAssetsEndpoint,
	useAssetCategoriesEndpoint,
	useAgenciesEndpoint,
	useEmployeesEndpoint,
	useFundSourcesEndpoint,
	useInstitutesEndpoint,
	useLocationsEndpoint,
} from 'Endpoints';
import { makeStyles, FormControl, Grid, LinearProgress, Button } from '@material-ui/core';
import { AssetSchema } from 'Assets/Forms';
import {
	AgencySelect,
	CategorySelect,
	EmployeeSelect,
	FormDebugging,
	FundSourceSelect,
	GlobalDatePicker,
	InstituteSelect,
	LocationSelect,
	StatusSelect,
} from 'Common/FormFields';
import { ReactHelper } from 'Common/Helpers';
import { EnhancedButton } from 'Common/Elements';
import { DeleteConfirmation } from 'Common/Modals';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sortByName } from 'Employees/Helpers';

export interface IAssetEditDetailsViewProps {
	asset: AssetModels.AssetDetails;
}

const useStyles = makeStyles(() => ({
	rentableContainer: {
		alignItems: 'flex-end',
		display: 'flex',
	},
	formControl: {
		minWidth: '0',
		width: '100%',
		'& > div': {
			minWidth: '0',
		},
	},
}));

enum ModalOptions {
	None = 0,
	DeleteAsset = 1,
}

export const AssetEditDetailsView = (props: IAssetEditDetailsViewProps & RouteComponentProps<any>) => {
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);

	const assetsEndpoint = useAssetsEndpoint();
	const institutesEndpoint = useInstitutesEndpoint();
	const agenciesEndpoint = useAgenciesEndpoint();
	const assetCategoriesEndpoint = useAssetCategoriesEndpoint();
	const locationsEndpoint = useLocationsEndpoint();
	const employeesEndpoint = useEmployeesEndpoint();
	const fundSourcesEndpoint = useFundSourcesEndpoint();

	const nameof = ReactHelper.useNameof<AssetModels.EditAsset>();
	const classes = useStyles();

	const [statuses, setStatuses] = useState<AssetModels.Status[]>([]);
	const [institutes, setInstitutes] = useState<InstituteModels.Institute[]>([]);
	const [agencies, setAgencies] = useState<AgencyModels.AgrilifeAgency[]>([]);
	const [assetCategories, setAssetCategories] = useState<AssetCategoryModels.AssetCategory[]>([]);
	const [locations, setLocations] = useState<LocationModels.Location[]>([]);
	const [employees, setEmployees] = useState<EmployeeModels.EmployeeSummary[]>([]);
	const [fundSources, setFundSources] = useState<FundSourceModels.FundSource[]>([]);

	useEffect(() => {
		assetsEndpoint.Statuses().then(r => r && setStatuses(r));
		institutesEndpoint.Get().then(r => r && setInstitutes(r));
		agenciesEndpoint.Get().then(r => r && setAgencies(r));
		assetCategoriesEndpoint.Categories().then(r => r && setAssetCategories(r));
		locationsEndpoint.Get().then(r => r && setLocations(r));
		employeesEndpoint.GetEmployees().then(r => r && setEmployees(r.sort(sortByName)));
		fundSourcesEndpoint.Get().then(r => r && setFundSources(r));
	}, []);

	const handleDeleteConfirm = () => {
		assetsEndpoint.Delete(props.asset.id).then(r => {
			if (r) {
				setOpenModal(ModalOptions.None);
				props.history.push('/');
			}
		});
	};

	if (!statuses || !institutes || !agencies || !assetCategories || !locations || !employees || !fundSources) {
		return <LinearProgress />;
	}

	const editAsset = new AssetModels.EditAsset(props.asset);

	return (
		<React.Fragment>
			<Formik
				initialValues={editAsset}
				validationSchema={AssetSchema.EditAssetSchema}
				onSubmit={(values, actions) => {
					assetsEndpoint.Edit(props.asset.id, values).finally(() => actions.setSubmitting(false));
				}}
			>
				{formprops => {
					return (
						<Form>
							<Grid container={true} justify="space-between">
								<Grid item={true} xs={12}>
									<Grid container={true} alignItems="center">
										<Grid item={true} xs={6}>
											<FormControl fullWidth={true}>
												<StatusSelect statuses={statuses} name={nameof('statusId')} />
											</FormControl>
										</Grid>
									</Grid>
								</Grid>
								<Grid item={true} xs={12}>
									<FormControl className={classes.formControl}>
										<Field label="Asset Name" name={nameof('name')} component={TextField} />
									</FormControl>
								</Grid>
							</Grid>
							<Grid container={true} spacing={2}>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<Field
											label="Asset Number"
											name={nameof('assetNumber')}
											component={TextField}
										/>
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<InstituteSelect institutes={institutes} name={nameof('instituteId')} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<AgencySelect agencies={agencies} name={nameof('agrilifeAgencyId')} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<Field label="Make" name={nameof('make')} component={TextField} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<Field label="Model" name={nameof('model')} component={TextField} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<CategorySelect categories={assetCategories} name={nameof('categoryId')} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<Field
											label="Serial Number"
											name={nameof('serialNumber')}
											component={TextField}
										/>
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<Field label="Vendor" name={nameof('vendor')} component={TextField} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<Field
											component={GlobalDatePicker}
											name={nameof('purchaseDate')}
											label="Purchase Date"
										/>
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<LocationSelect locations={locations} name={nameof('locationId')} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<Field
											label="Location Details"
											name={nameof('locationDetails')}
											component={TextField}
										/>
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<EmployeeSelect employees={employees} name={nameof('employeeId')} />
									</FormControl>
								</Grid>
								<Grid item={true} sm={4}>
									<FormControl className={classes.formControl}>
										<FundSourceSelect fundSources={fundSources} name={nameof('fundSourceId')} />
									</FormControl>
								</Grid>
								{formprops.values.fundSourceId === FundSourceModels.FundSourceValue.SRS && (
									<Grid item={true} sm={4}>
										<FormControl className={classes.formControl}>
											<Field
												label="Account Number"
												name={nameof('accountNumber')}
												component={TextField}
											/>
										</FormControl>
									</Grid>
								)}
							</Grid>
							<Grid container={true}>
								<Grid item={true} xs={6}>
									<FormControl>
										<Button
											type="button"
											variant="text"
											onClick={() => setOpenModal(ModalOptions.DeleteAsset)}
										>
											Delete Asset
										</Button>
									</FormControl>
								</Grid>
								<Grid item={true} xs={6}>
									<FormControl className={classes.formControl}>
										{formprops.isSubmitting && <LinearProgress />}
										<EnhancedButton
											submit={true}
											isSubmitting={formprops.isSubmitting}
											action="edit"
											label=""
										/>
									</FormControl>
								</Grid>
							</Grid>

							<FormDebugging />
						</Form>
					);
				}}
			</Formik>

			<DeleteConfirmation
				isOpen={openModal === ModalOptions.DeleteAsset}
				onCancel={() => setOpenModal(ModalOptions.None)}
				label="asset"
				onConfirm={handleDeleteConfirm}
				additionalWarning="This is a destructive action that cannot be
				undone. Please note, assets should only be deleted if they were created in error or are a duplicate. Surplused or transferred assets should be changed to the appropriate status."
			/>
		</React.Fragment>
	);
};

export const AssetEditDetailsViewWithRouter = withRouter(AssetEditDetailsView);
