import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

interface ISidebarProps {
	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
	sidebar: {
		backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[800],
		height: '100vh',
		paddingTop: '32px',
		width: '264px',
		position: 'static',
	},
}));

export const Sidebar = (props: ISidebarProps) => {
	const classes = useStyles();

	return <div className={classes.sidebar}>{props.children}</div>;
};
