import { AssetCategory, AddAssetCategory } from 'Models/AssetCategoryModels';
import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';

export const useAssetCategoriesEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('assetcategories');

	const Categories = () =>
		ep.Get<AssetCategory[]>().catch(() => {
			useError('Error getting asset categories');
			return [] as AssetCategory[];
		});

	const Add = (dto: AddAssetCategory) =>
		ep
			.Post<AssetCategory>(dto)
			.then(r => {
				useSuccess('Asset category added');
				return r;
			})
			.catch(() => {
				useError('Error adding asset category');
				return undefined;
			});

	return { Categories, Add, IsLoading: ep.IsLoading };
};
