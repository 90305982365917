import React from 'react';
import { useAppState } from 'Context/AppProvider';
import { AuthHelper } from 'Common/Helpers';
type RoleName = 'Admin' | 'SuperUser';

export interface IProtectedComponentProps {
	children?: any; // https://stackoverflow.com/a/55858755
	allowedRoles?: RoleName[];
	restrictedRoles?: RoleName[];
}

type Props = IProtectedComponentProps;

export const ProtectedComponent = (props: Props) => {
	const state = useAppState();
	const userIsAuthorized = (): boolean => {
		if (!state.CurrentUser) {
			return false;
		}

		if (props.allowedRoles && props.allowedRoles.includes('Admin')) {
			const isAdmin = AuthHelper.isAdmin(state.CurrentUser);

			if (isAdmin) {
				return true;
			}
		}

		if (props.allowedRoles && props.allowedRoles.includes('SuperUser')) {
			const isSuperUser = AuthHelper.isSuperUser(state.CurrentUser);

			if (isSuperUser) {
				return true;
			}
		}

		if (props.restrictedRoles && props.restrictedRoles.includes('Admin')) {
			const isAdmin = AuthHelper.isAdmin(state.CurrentUser);

			if (!isAdmin) {
				return true;
			}
		}

		if (props.restrictedRoles && props.restrictedRoles.includes('SuperUser')) {
			const isSuperUser = AuthHelper.isSuperUser(state.CurrentUser);

			if (!isSuperUser) {
				return true;
			}
		}

		return false;
	};

	return userIsAuthorized() ? props.children : <React.Fragment />;
};
