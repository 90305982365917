import React from 'react';

import { Typography } from '@material-ui/core';

interface IPageHeaderProps {
	text: string;
}

export const PageHeader = (props: IPageHeaderProps) => {
	return (
		<Typography component="h1" variant="h4">
			{props.text}
		</Typography>
	);
};
