import React from 'react';
import { AssetDetails } from 'Models/AssetModels';
import { FundSourceValue } from 'Models/FundSourceModels';
import { makeStyles, Grid, Divider, Typography } from '@material-ui/core';
import { StatusChip, PageHeader } from 'Common/Elements';
import { DateHelper } from 'Common/Helpers';

type gridNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

interface ITableItemProps {
	label: string;
	value: string;
	sm?: gridNumber;
	md?: gridNumber;
	lg?: gridNumber;
	xl?: gridNumber;
}

const AssetItem = (props: ITableItemProps) => {
	const classes = useStyles();
	return (
		<Grid item={true} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
			<Typography component="span" className={classes.assetItemLabel}>
				{props.label}
			</Typography>
			<Typography component="span" className={classes.assetItemValue}>
				{props.value}
			</Typography>
		</Grid>
	);
};

export interface IAssetDetailsViewProps {
	asset: AssetDetails;
}

const useStyles = makeStyles(theme => ({
	assetItemLabel: {
		display: 'block',
		color: '#777776',
		fontSize: '14px',
		letterSpacing: '.5px',
		textTransform: 'uppercase',
	},
	assetItemValue: {
		display: 'block',
		fontSize: '18px',
		fontWeight: 500,
		marginBottom: theme.spacing(2),
	},
	chipContainer: {
		marginBottom: theme.spacing(1),
	},
	statusChip: {
		marginRight: theme.spacing(1),
	},
	divider: {
		marginBottom: theme.spacing(4),
		marginTop: theme.spacing(3),
	},
}));

export const AssetDetailsView = (props: IAssetDetailsViewProps) => {
	const classes = useStyles();
	console.log('asset', props.asset);
	return (
		<React.Fragment>
			<Grid container={true} justify="space-between">
				<Grid item={true} className={classes.chipContainer}>
					<StatusChip status={props.asset.statusName} className={classes.statusChip} />
				</Grid>
				<Grid item={true} xs={12}>
					<PageHeader text={props.asset.name} />
				</Grid>
			</Grid>
			<Divider className={classes.divider} />
			<Grid container={true}>
				<AssetItem label={'Asset Number'} value={props.asset.assetNumber} sm={4} />
				<AssetItem label={'Institute'} value={props.asset.instituteShortName} sm={4} />
				<AssetItem label={'Agrilife Agency'} value={props.asset.agrilifeAgencyName} sm={4} />
				<AssetItem label={'Make'} value={props.asset.make} sm={4} />
				<AssetItem label={'Model'} value={props.asset.model} sm={4} />
				<AssetItem label={'Category'} value={props.asset.categoryName} sm={4} />
				<AssetItem label={'Serial Number'} value={props.asset.serialNumber} sm={4} />
				<AssetItem label={'Vendor'} value={props.asset.vendor} sm={4} />
				<AssetItem
					label={'Purchase Date'}
					value={DateHelper.getShortDateString(props.asset.purchaseDate)}
					sm={4}
					lg={3}
				/>
				<AssetItem label={'Location'} value={props.asset.locationName} sm={4} />
				<AssetItem label={'Location Details'} value={props.asset.locationDetails} sm={4} />
				<AssetItem label={'Assigned To'} value={props.asset.employeeName} sm={4} />
				<AssetItem label={'Fund Source'} value={props.asset.fundSourceName} sm={4} />
				{props.asset.fundSourceId === FundSourceValue.SRS && (
					<AssetItem label={'Account Number'} value={props.asset.accountNumber} sm={4} />
				)}
			</Grid>
		</React.Fragment>
	);
};
