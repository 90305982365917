import { Configuration, LogLevel } from '@azure/msal-browser';
import settings from 'settings';

// putting everything but the redirect URI in here since that's the only thing
// that changes between environments. We also needed to import other modules for the log levels
// and the standalone settings.js file wouldn't allow us to do that
export const msalConfig: Configuration = {
	auth: {
		clientId: '7e79ef6d-6d79-4bdb-8ebe-19c7cd3afcc5',
		authority: 'https://login.microsoftonline.com/9fd7580a-6472-4d9c-a142-d131d3a7a116/',
		redirectUri: settings.azureAd.redirectUri,
	},
	cache: {
		cacheLocation: 'sessionStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		loggerOptions: {
			loggerCallback: (level, message, containsPii) => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
		},
	},
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
	scopes: ['api://3b0f0bda-f77e-4146-a6bf-5350436874f6/.default'],
};
