import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { Status } from 'Models/AssetModels';

export interface IStatusSelectProps {
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The array of statuses that will be shown as dropdown items */
	statuses: Status[];
	/** The label/placeholder for the select element */
	label?: string;
	/** Classes to be passed to the component */
}

/** Shows active statuses */
export const StatusSelect = (props: IStatusSelectProps) => {
	return (
		<Field component={TextField} name={props.name} select={true} label={props.label || 'Status'} fullWidth={true}>
			<MenuItem key={`Status-select-none`} value="0">
				- Select -
			</MenuItem>
			{props.statuses
				.filter(a => a.active)
				.map(a => {
					return (
						<MenuItem key={`Status-select-${a.id}`} value={a.id}>
							{a.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
