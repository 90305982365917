import { AddSurplus } from 'Models/SurplusModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useSurplusEndpoint = () => {
	const { useError, useSuccess } = useNotifications();
	const ep = Endpoint('surplus');

	const Add = (dto: AddSurplus) =>
		ep
			.Post<number>(dto)
			.then(r => {
				useSuccess('Asset surplused');
				return r;
			})
			.catch(() => {
				useError('Error surplusing asset');
				return undefined;
			});

	return { Add, IsLoading: ep.IsLoading };
};
