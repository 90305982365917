import React from 'react';
import MaterialTable from 'material-table';
import { AssetSummary } from 'Models/AssetModels';
import { TableIcons, TableStyles } from '.';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { Link } from 'react-router-dom';

export interface IAssetTableProps {
	assets: AssetSummary[];
	isLoading?: boolean;
	title?: string;
}

export const AssetTable = (props: IAssetTableProps) => {
	const nameof = useNameof<AssetSummary>();
	return (
		<MaterialTable
			style={{ marginBottom: 75 }} // make enough room for the fab to be below pagination controls
			columns={[
				{
					title: 'Name',
					field: nameof('name'),
					render: rowData => <Link to={'/assets/' + rowData.id}>{rowData.name}</Link>,
				},
				{ title: 'Type', field: nameof('typeName') },
				{ title: 'Category', field: nameof('categoryName') },
				{ title: 'Institute', field: nameof('instituteName') },
				{ title: 'Status', field: nameof('statusName') },
				{
					title: 'Employee',
					field: nameof('employeeName'),
					render: rowData => <Link to={'/employees/' + rowData.employeeId}>{rowData.employeeName}</Link>,
				},
				{ title: 'Location', field: nameof('locationName') },
				{ title: 'Asset Number', field: nameof('assetNumber') },
			]}
			data={props.assets}
			title={props.title || 'Assets'}
			icons={TableIcons}
			options={{
				pageSize: 10,
				headerStyle: TableStyles.headerStyle,
				rowStyle: TableStyles.rowStyle,
				actionsColumnIndex: -1,
			}}
			isLoading={props.isLoading}
		/>
	);
};
