import React from 'react';
import { makeStyles, Chip } from '@material-ui/core';
import { red, indigo } from '@material-ui/core/colors';
import { Check as CheckIcon, Clear as ClearIcon } from '@material-ui/icons';

interface IRentableChipProps {
	isRentable: boolean;
}

const useStyles = makeStyles(() => ({
	chip: {
		color: '#fff',
		textTransform: 'uppercase',
	},
	rentable: {
		background: indigo[900],
	},
	notRentable: {
		background: red[900],
	},
	icon: {
		color: '#fff',
	},
}));

export const RentableChip = (props: IRentableChipProps) => {
	const classes = useStyles();
	const chipText = props.isRentable ? 'Rentable' : 'Not Rentable';
	const chipClass = props.isRentable ? classes.rentable : classes.notRentable;
	const ChipIcon = props.isRentable ? CheckIcon : ClearIcon;

	return (
		<Chip
			label={chipText}
			className={`${classes.chip} ${chipClass}`}
			icon={<ChipIcon className={classes.icon} />}
			size="small"
		/>
	);
};
