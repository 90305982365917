import React, { useState } from 'react';
import { NoteModels } from 'Models';
import { useNotesEndpoint } from 'Endpoints';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { makeStyles, Grid, Chip, Button, Paper, LinearProgress, CircularProgress, Tooltip } from '@material-ui/core';
import { AssetSchema } from '../Forms';
import { ReactHelper, DateHelper } from 'Common/Helpers';
import { PostAdd } from '@material-ui/icons';

export interface INotesTabContentProps {
	assetId: number;
	notes: ReadonlyArray<NoteModels.Note>;
	isEditing: boolean;
}

const useStyles = makeStyles(theme => ({
	noteItem: {
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(1),
	},
	noteHeader: {
		marginBottom: theme.spacing(0.75),
	},
	noteName: {
		fontWeight: 500,
		marginRight: theme.spacing(1),
	},
	noteDate: {
		color: '#777776',
	},
	noteText: {
		borderLeft: `3px solid ${theme.palette.primary.main}`,
		paddingLeft: theme.spacing(1),
	},
	paper: {
		alignItems: 'center',
		display: 'flex',
		margin: `${theme.spacing(1.5)}px 0 0`,
		padding: 0,
	},
	field: {
		flex: 1,
		margin: `${theme.spacing(1)}px 0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
	},
	button: {
		alignSelf: 'stretch',
		borderBottomLeftRadius: 0,
		borderTopLeftRadius: 0,
		marginLeft: theme.spacing(1),
	},
}));

export const NotesTabContent = (props: INotesTabContentProps) => {
	const ep = useNotesEndpoint();
	const classes = useStyles();
	const nameof = ReactHelper.useNameof<NoteModels.AddNote>();
	const [notes, setNotes] = useState(props.notes);

	return (
		<React.Fragment>
			{notes.map(n => {
				return (
					<Grid container={true} key={n.id} className={classes.noteItem}>
						<Grid item={true} xs={12}>
							<Grid
								container={true}
								justify="space-between"
								alignItems="center"
								className={classes.noteHeader}
							>
								<Grid item={true} className={classes.noteName}>
									{n.createdBy}
								</Grid>
								<Grid item={true} className={classes.noteDate}>
									<Chip label={DateHelper.getShortDateString(n.submittedAt)} size="small" />
								</Grid>
							</Grid>
						</Grid>
						<Grid item={true} xs={12} className={classes.noteText}>
							{n.text}
						</Grid>
					</Grid>
				);
			})}
			<Grid container={true}>
				<Grid item={true} xs={12}>
					<Formik
						initialValues={new NoteModels.AddNote(props.assetId)}
						validationSchema={AssetSchema.AddNoteSchema}
						onSubmit={(values, actions) => {
							ep.Add(values)
								.then(r => r && setNotes([...notes, r]))
								.then(() => {
									actions.setSubmitting(false);
									actions.resetForm();
								});
						}}
					>
						{formprops => (
							<Form>
								<Paper className={classes.paper}>
									{formprops.isSubmitting && <LinearProgress />}
									<Field
										className={classes.field}
										name={nameof('text')}
										component={TextField}
										inputProps={{
											placeholder: 'Note Text',
										}}
									/>

									<Button
										type="submit"
										color="primary"
										variant="contained"
										className={classes.button}
										disabled={formprops.isSubmitting}
										onSubmit={formprops.submitForm}
									>
										<Tooltip title="Add Note" placement="bottom">
											{formprops.isSubmitting ? <CircularProgress /> : <PostAdd />}
										</Tooltip>
									</Button>
								</Paper>
							</Form>
						)}
					</Formik>
				</Grid>
			</Grid>
		</React.Fragment>
	);
};
