import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { Formik, Field, Form, FormikHelpers } from 'formik';
import { useAssetsEndpoint } from 'Endpoints/AssetsEndpoint';
import { useAssetCategoriesEndpoint } from 'Endpoints/AssetCategoriesEndpoint';
import { AssetCategoryModels } from 'Models';
import { FormControl, LinearProgress, Grid } from '@material-ui/core';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { CategorySchema } from 'Categories/Forms';
import { TypeSelect } from 'Common/FormFields';
import { AssetType } from 'Models/AssetModels';
import { TextField } from 'formik-material-ui';
import { StyledCard, EnhancedButton } from 'Common/Elements';

export const Add = () => {
	useEffect(() => {
		DOMHelper.setPageTitle('Add Category');
		endpoint.Types().then(r => setTypes(r));
	}, []);

	const endpoint = useAssetsEndpoint();

	const categoriesEndpoint = useAssetCategoriesEndpoint();

	const nameof = useNameof<AssetCategoryModels.AddAssetCategory>();

	const [category, setCategory] = useState(new AssetCategoryModels.AddAssetCategory());

	const [types, setTypes] = useState<AssetType[]>([]);

	const handleSuccess = (actions: FormikHelpers<AssetCategoryModels.AddAssetCategory>) => {
		setCategory(new AssetCategoryModels.AddAssetCategory());
		actions.setSubmitting(false);
		actions.resetForm();
	};

	return (
		<div>
			<React.Fragment>
				<Grid container={true}>
					<StyledCard title="Add Category">
						<Formik
							initialValues={category}
							validationSchema={CategorySchema.CategorySchema}
							onSubmit={(values, actions) => {
								categoriesEndpoint.Add(values).then(r => r && handleSuccess(actions));
							}}
						>
							{formprops => (
								<Form>
									<FormControl fullWidth={true}>
										<TypeSelect types={types} name={nameof('assetTypeId')} />
									</FormControl>
									<FormControl fullWidth={true}>
										<Field label="Category name" name={nameof('name')} component={TextField} />
									</FormControl>
									<FormControl fullWidth={true}>
										{formprops.isSubmitting && <LinearProgress />}
										<EnhancedButton
											submit={true}
											disabled={categoriesEndpoint.IsLoading}
											isSubmitting={formprops.isSubmitting}
											action="add"
											label="Category"
										/>
									</FormControl>
								</Form>
							)}
						</Formik>
					</StyledCard>
				</Grid>
			</React.Fragment>
		</div>
	);
};
