import React from 'react';

import { AppBar as MuiAppBar, Toolbar, makeStyles, Theme, Hidden, IconButton, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { useAppDispatch, useAppState } from 'Context/AppProvider';
import { AppBarSearch } from 'Layout';
import LightIcon from '@material-ui/icons/Brightness7';
import DarkIcon from '@material-ui/icons/Brightness4';

export interface IAppBarProps {
	onAddClick?: () => void;
	onMenuClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
	grow: {
		flexGrow: 1,
	},
	sectionDesktop: {
		display: 'none',
		[theme.breakpoints.up('md')]: {
			display: 'flex',
		},
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
}));

export const AppBar = (props: IAppBarProps) => {
	const state = useAppState();
	const dispatch = useAppDispatch();

	const classes = useStyles();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	return (
		<MuiAppBar position="fixed">
			<Toolbar>
				<Hidden lgUp={true} implementation="css">
					<IconButton color="inherit" aria-label="Open drawer" onClick={props.onMenuClick}>
						<MenuIcon />
					</IconButton>
				</Hidden>
				<div className={classes.grow} />
				<AppBarSearch />

				{state.Theme === 'light' ? (
					<IconButton
						onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'dark' })}
						color="inherit"
						size="small"
						className={classes.menuButton}
					>
						<DarkIcon />
					</IconButton>
				) : (
					<IconButton
						onClick={() => dispatch({ type: 'APP/SET_THEME', payload: 'light' })}
						color="inherit"
						size="small"
						className={classes.menuButton}
					>
						<LightIcon />
					</IconButton>
				)}

				<IconButton
					aria-label="current user account and sign out"
					aria-controls="menu-appbar"
					aria-haspopup="true"
					onClick={handleMenu}
					color="inherit"
				>
					<AccountCircle />
				</IconButton>
				<Menu
					id="menu-appbar"
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted={true}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={open}
					onClose={handleClose}
				>
					<MenuItem onClick={handleClose}>Profile</MenuItem>
					<MenuItem onClick={() => dispatch({ type: 'APP/USER_LOGGED_OUT' })}>Sign Out</MenuItem>
				</Menu>
			</Toolbar>
		</MuiAppBar>
	);
};
