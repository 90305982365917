import React from 'react';
import { StatusValue } from 'Models/AssetModels';
import { makeStyles, Chip } from '@material-ui/core';
import { red, green, grey, orange, blue, blueGrey, yellow, brown } from '@material-ui/core/colors';
import {
	DriveEta as GenericIcon,
	Add as InServiceIcon,
	Clear as NotUsedIcon,
	LocalShipping as OnRentalIcon,
	Build as RepairIcon,
	Help as LostIcon,
	Remove as BrokenIcon,
	HowToVote as SurplusedIcon,
	Compare as TransferredIcon,
} from '@material-ui/icons';

interface IStatusChipProps {
	status: StatusValue;
	className?: string;
}

const useStyles = makeStyles(theme => ({
	chip: {
		color: '#fff',
		textTransform: 'uppercase',
	},
	icon: {
		color: '#fff',
	},
	inService: {
		background: green[600],
	},
	notUsed: {
		background: grey[700],
	},
	onRental: {
		background: orange[900],
	},
	repair: {
		background: blueGrey[500],
	},
	lost: {
		background: brown[700],
	},
	broken: {
		background: red[800],
	},
	surplused: {
		background: yellow[700],
		color: theme.palette.primary.dark,
	},
	transferred: {
		background: blue[700],
	},
}));

export const StatusChip = (props: IStatusChipProps) => {
	const classes = useStyles();

	const { status } = props;
	let chipClass = '';
	let ChipIcon = GenericIcon;

	if (status === StatusValue.InService) {
		chipClass = classes.inService;
		ChipIcon = InServiceIcon;
	} else if (status === StatusValue.NotUsed) {
		chipClass = classes.notUsed;
		ChipIcon = NotUsedIcon;
	} else if (status === StatusValue.OnRental) {
		chipClass = classes.onRental;
		ChipIcon = OnRentalIcon;
	} else if (status === StatusValue.Repair) {
		chipClass = classes.repair;
		ChipIcon = RepairIcon;
	} else if (status === StatusValue.Lost) {
		chipClass = classes.lost;
		ChipIcon = LostIcon;
	} else if (status === StatusValue.Broken) {
		chipClass = classes.broken;
		ChipIcon = BrokenIcon;
	} else if (status === StatusValue.Surplused) {
		chipClass = classes.surplused;
		ChipIcon = SurplusedIcon;
	} else if (status === StatusValue.Transferred) {
		chipClass = classes.transferred;
		ChipIcon = TransferredIcon;
	}

	return (
		<Chip
			label={status}
			className={`${classes.chip} ${chipClass} ${props.className}`}
			icon={<ChipIcon className={classes.icon} />}
			size="small"
		/>
	);
};
