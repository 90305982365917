import { AppModels } from 'Models';
import settings from 'settings';
import moment, { duration } from 'moment';

// pass in user and admin object with groups and users arrays
export const isAdmin = (user: AppModels.LoggedInUser) => {
	if (settings.authorization.adminUsers.includes(user.preferredUsername.toLowerCase())) return true;

	let isInAdminGroup = false;
	user.roles.forEach((group: string) => {
		if (settings.authorization.adminGroups.includes(group)) isInAdminGroup = true;
	});

	return isInAdminGroup;
};

// pass in user and supers object with groups and users arrays
export const isSuperUser = (user: AppModels.LoggedInUser) => {
	if (settings.authorization.superUsers.includes(user.preferredUsername.toLowerCase())) return true;

	let isInSuperUserGroup = false;
	user.roles.forEach((group: string) => {
		if (settings.authorization.superUserGroups.includes(group)) isInSuperUserGroup = true;
	});

	return isInSuperUserGroup;
};

/** Returns a boolean indicating if the supplied lastActivity is within the past hour */
export const isActive = (lastActivity: Date): boolean => {
	const currentTime = moment();
	const last = moment(lastActivity);
	const d = duration(currentTime.diff(last));

	// if last user activity is greater than an hour ago, renew the token
	if (d.asSeconds() < 3600) {
		console.log('user has been active within the past hour');
		return true;
	}

	return false;
};
