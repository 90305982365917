import { AgrilifeAgency } from 'Models/AgencyModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useAgenciesEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('agencies');

	const Get = () =>
		ep.Get<AgrilifeAgency[]>().catch(() => {
			useError('Error getting agencies');
			return [] as AgrilifeAgency[];
		});

	return { Get, IsLoading: ep.IsLoading };
};
