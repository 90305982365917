import React from 'react';
import { Grid } from '@material-ui/core';
import { StyledCard } from 'Common/Elements';
import { SurplusAssetForm } from 'Surplus/Forms';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export const Add = (props: RouteComponentProps<any>) => {
	return (
		<Grid container={true}>
			<Grid item={true} lg={4}>
				<StyledCard title="Surplus Asset">
					<SurplusAssetForm onSuccess={() => props.history.push('/surplus')} id={props.match.params.id} />
				</StyledCard>
			</Grid>
		</Grid>
	);
};

export default withRouter(Add);
