import React from 'react';
import { RouteComponentProps, Route, Redirect } from 'react-router-dom';
import { useAppState } from 'Context/AppProvider';
import { AuthHelper } from 'Common/Helpers';
type RoleName = 'Admin' | 'SuperUser';

export interface IProtectedRouteProps {
	allowedRoles?: RoleName[];
	restrictedRoles?: RoleName[];
	component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
	exact?: boolean;
	path: string;
}

export const ProtectedRoute = ({
	component: ProtectedComponent,
	allowedRoles,
	restrictedRoles,
	path,
	...rest
}: IProtectedRouteProps) => {
	const state = useAppState();
	const userIsAuthorized = (): boolean => {
		if (!state.CurrentUser) {
			return false;
		}

		if (allowedRoles && allowedRoles.includes('Admin')) {
			const isAdmin = AuthHelper.isAdmin(state.CurrentUser);

			if (isAdmin) {
				return true;
			}
		}

		if (allowedRoles && allowedRoles.includes('SuperUser')) {
			const isSuperUser = AuthHelper.isSuperUser(state.CurrentUser);

			if (isSuperUser) {
				return true;
			}
		}

		if (restrictedRoles && restrictedRoles.includes('Admin')) {
			const isAdmin = AuthHelper.isAdmin(state.CurrentUser);

			if (!isAdmin) {
				return true;
			}
		}

		if (restrictedRoles && restrictedRoles.includes('SuperUser')) {
			const isSuperUser = AuthHelper.isSuperUser(state.CurrentUser);

			if (!isSuperUser) {
				return true;
			}
		}

		return false;
	};

	return (
		<Route
			{...rest}
			path={path}
			render={(renderProps: RouteComponentProps) =>
				userIsAuthorized() ? (
					<ProtectedComponent {...renderProps} />
				) : (
					<Redirect from={path} to="/not-authorized" />
				)
			}
		/>
	);
};
