import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';
import { File, FileCategory } from 'Models/FileModels';

export const useFilesEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('files');

	const Add = (dto: FormData) =>
		ep
			.Post<File>(dto)
			.then(r => {
				useSuccess('File added');
				return r;
			})
			.catch(() => {
				useError('Error adding file');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>(id)
			.then(r => {
				useSuccess('File deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting file');
				return undefined;
			});

	const Categories = () =>
		ep.Get<FileCategory[]>('categories').catch(() => {
			useError('Error getting file categories');
			return [] as FileCategory[];
		});

	const Download = (id: number) =>
		ep.Download(id).catch(() => {
			useError('Error getting file contents');
			return undefined;
		});

	return { Add, Delete, Download, Categories, IsLoading: ep.IsLoading };
};
