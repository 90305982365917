import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { AssetType } from 'Models/AssetModels';

export interface ITypeSelectProps {
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The array of types that will be shown as dropdown items */
	types: AssetType[];
	/** The label/placeholder for the select element */
	label?: string;
	/** Classes to be passed to the component */
}

/** Shows active types */
export const TypeSelect = (props: ITypeSelectProps) => {
	return (
		<Field component={TextField} name={props.name} select={true} label={props.label || 'Type'}>
			<MenuItem key={`Type-select-none`} value="0">
				- Select -
			</MenuItem>
			{props.types.map(a => {
				return (
					<MenuItem key={`type-select-${a.id}`} value={a.id}>
						{a.name}
					</MenuItem>
				);
			})}
		</Field>
	);
};
