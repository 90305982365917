import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { AssetCategory } from 'Models/AssetCategoryModels';

export interface ICategorySelectProps {
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The array of categories that will be shown as dropdown items */
	categories: AssetCategory[];
	/** The label/placeholder for the select element */
	label?: string;
	/** Classes to be passed to the component */
}

/** Shows active categories */
export const CategorySelect = (props: ICategorySelectProps) => {
	return (
		<Field component={TextField} name={props.name} select={true} label={props.label || 'Category'} fullWidth={true}>
			<MenuItem key={`Category-select-none`} value="0">
				- Select -
			</MenuItem>
			{props.categories
				.filter(a => a.active)
				.map(a => {
					return (
						<MenuItem key={`Category-select-${a.id}`} value={a.id}>
							{a.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
