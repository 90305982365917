import React, { useState, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { DOMHelper, TimeHelper } from 'Common/Helpers';
import { AssetDetails } from 'Models/AssetModels';
import { useAssetsEndpoint } from 'Endpoints';
import { AssetDetailsView, AssetEditDetailsView, FilesTabContent, NotesTabContent } from '../Components';
import { makeStyles, LinearProgress, Grid, Button, CardContent, Card, Tabs, Tab, Theme } from '@material-ui/core';
import { useAppDispatch } from 'Context/AppProvider';
import { SidebarBox } from 'Common/Elements/Sidebar';

import {
	Info as InfoIcon,
	AccessAlarm as SurplusIcon,
	Repeat as TransferIcon,
	LocalShipping as RentalIcon,
} from '@material-ui/icons';
import { ProtectedComponent } from 'Common/Utilities';

const useStyles = makeStyles((theme: Theme) => ({
	boxLabel: {
		display: 'block',
		color: theme.palette.type === 'light' ? theme.palette.grey[600] : theme.palette.grey[200],
		fontSize: '12px',
		letterSpacing: '.5px',
		marginBottom: theme.spacing(0.25),
		textTransform: 'uppercase',
	},
	boxValue: {
		display: 'block',
		fontSize: '16px',
		fontWeight: 500,
		marginBottom: theme.spacing(1.5),
	},
	leftContent: {
		paddingRight: theme.spacing(2),
	},
	tabContainer: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	},
	buttonAnchor: {
		textDecoration: 'none',
	},
}));

interface ISidebarContentRowProps {
	label: string;
	value: string | undefined;
}

const SidebarContentRow = (props: ISidebarContentRowProps) => {
	const classes = useStyles();
	return (
		<React.Fragment>
			<span className={classes.boxLabel}>{props.label}</span>
			<span className={classes.boxValue}>{props.value}</span>
		</React.Fragment>
	);
};

const sidebarContent = (asset: AssetDetails) => {
	return (
		<div>
			<SidebarBox title="Asset History" icon={<InfoIcon />}>
				<SidebarContentRow label="Created" value={TimeHelper.relativeTime(asset.submittedAt)} />

				<SidebarContentRow label="Created By" value={asset.createdBy} />

				{asset.updatedAt && (
					<React.Fragment>
						<SidebarContentRow label="Updated" value={TimeHelper.relativeTime(asset.updatedAt)} />

						<SidebarContentRow label="Updated By" value={asset.updatedBy} />
					</React.Fragment>
				)}
			</SidebarBox>
			{asset.surplusedOn && (
				<SidebarBox title="Surplus Info" icon={<SurplusIcon />}>
					<SidebarContentRow label="Surplused" value={TimeHelper.relativeTime(asset.surplusedOn)} />

					<SidebarContentRow label="Reason" value={asset.surplusReason} />

					<SidebarContentRow label="Created By" value={asset.surplusCreatedBy} />
				</SidebarBox>
			)}
			{asset.transferredOn && (
				<SidebarBox title="Transfer Info" icon={<TransferIcon />}>
					<SidebarContentRow label="Transferred" value={TimeHelper.relativeTime(asset.transferredOn)} />

					<SidebarContentRow label="Reason" value={asset.transferReason} />

					<SidebarContentRow label="Transferred To" value={asset.transferredTo} />

					<SidebarContentRow label="Created By" value={asset.transferCreatedBy} />
				</SidebarBox>
			)}
			{asset.rentalReason && (
				<SidebarBox title="Rental Info" icon={<RentalIcon />}>
					<SidebarContentRow label="Rented" value={TimeHelper.relativeTime(asset.rentedOn as Date)} />

					<SidebarContentRow label="Returns" value={TimeHelper.relativeTime(asset.returnOn as Date)} />

					<SidebarContentRow label="Reason" value={asset.rentalReason} />

					<SidebarContentRow label="Rented By" value={asset.rentedByName} />
				</SidebarBox>
			)}
		</div>
	);
};

enum TabOptions {
	Files = 0,
	Notes = 1,
}

export const Details = (props: RouteComponentProps<any>) => {
	const ep = useAssetsEndpoint();
	const dispatch = useAppDispatch();
	const [asset, setAsset] = useState<AssetDetails>();
	const [selectedTab, setSelectedTab] = useState(TabOptions.Files);

	const [isEditing, setIsEditing] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		DOMHelper.setPageTitle('Asset Details');
		ep.Asset(props.match.params.id).then(r => r && setAsset(r));

		return () => {
			showSidebar(false);
		};
	}, []);

	useEffect(() => {
		DOMHelper.setPageTitle(`${asset?.name} Details`);
		showSidebar(true);
	}, [asset]);

	const showSidebar = (show: boolean) => {
		const sidebarPayload: JSX.Element | undefined = asset ? sidebarContent(asset) : undefined;
		dispatch({ type: 'APP/SIDEBAR_CONTENT_SET', payload: sidebarPayload });
	};

	if (!asset) {
		return <LinearProgress />;
	}

	return (
		<Grid container={true}>
			<Grid item={true} sm={12} md={7} className={classes.leftContent}>
				<Card>
					<CardContent>
						<ProtectedComponent allowedRoles={['Admin']}>
							<Grid container={true} spacing={1} justify="flex-end">
								<Grid item={true}>
									{!isEditing ? (
										<Button size="small" onClick={() => setIsEditing(true)}>
											Edit
										</Button>
									) : (
										<Button color="default" size="small" onClick={() => setIsEditing(false)}>
											Stop Editing
										</Button>
									)}
								</Grid>
								{asset.statusName !== 'Surplused' && (
									<>
										<Grid item={true}>
											<Link to={`/transfers/add/${asset.id}`} className={classes.buttonAnchor}>
												<Button
													variant="contained"
													color="secondary"
													size="small"
													startIcon={<TransferIcon />}
												>
													Transfer
												</Button>
											</Link>
										</Grid>
										<Grid item={true}>
											<Link to={`/surplus/add/${asset.id}`} className={classes.buttonAnchor}>
												<Button
													variant="contained"
													color="secondary"
													size="small"
													startIcon={<SurplusIcon />}
												>
													Surplus
												</Button>
											</Link>
										</Grid>
									</>
								)}
							</Grid>
						</ProtectedComponent>
						{isEditing ? <AssetEditDetailsView asset={asset} /> : <AssetDetailsView asset={asset} />}
					</CardContent>
				</Card>
			</Grid>
			<Grid item={true} sm={12} md={5}>
				<Card>
					<CardContent>
						<Tabs
							value={selectedTab}
							onChange={(event: React.ChangeEvent<HTMLElement>, value: any) => setSelectedTab(value)}
							indicatorColor="primary"
							variant="scrollable"
							scrollButtons="auto"
						>
							<Tab label="Files" value={TabOptions.Files} />
							<Tab label="Notes" value={TabOptions.Notes} />
						</Tabs>
						{selectedTab === TabOptions.Files && (
							<div className={classes.tabContainer}>
								<FilesTabContent files={asset.files} assetId={asset.id} isEditing={isEditing} />
							</div>
						)}
						{selectedTab === TabOptions.Notes && (
							<div className={classes.tabContainer}>
								<NotesTabContent notes={asset.notes} assetId={asset.id} isEditing={isEditing} />
							</div>
						)}
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
};
