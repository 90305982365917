export const truncate = (str: string, length = 100, ending = '...'): string => {
	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	}
	return str;
};

export const isEmptyOrWhitespace = (str: string): boolean => {
	return !str || str.length === 0 || /^\s*$/.test(str);
};

export const currency = (x: number | string): string => {
	const parts = x.toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	if (parts.length === 1) {
		parts[1] = '00';
	}
	return parts.join('.');
};

export const makeUpperCase = (s: string) => {
	/** This will make the first letter of a word uppercase  */
	const x = s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
	return x;
};

export const uppercaseFirst = (s: string) => {
	/** This will make the first letter of a word or words uppercase  */
	let x = '';
	const words = [];
	if (s.indexOf(' ') !== -1) {
		const str = s.split(' ');
		// tslint:disable-next-line: prefer-for-of
		for (let i = 0; i < str.length; i++) {
			words.push(makeUpperCase(str[i]));
		}
		x = words.join(' ');
	} else {
		x = makeUpperCase(s);
	}
	return x;
};
