import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { AssetSummary } from 'Models/AssetModels';
import { useAssetsEndpoint } from 'Endpoints';
import { Grid, Fab, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { AssetTable } from 'Common/Tables';
import AddIcon from '@material-ui/icons/Add';
import { SurplusAssetForm } from 'Surplus/Forms';
import { ProtectedComponent } from 'Common/Utilities';

enum ModalOptions {
	None = 0,
	Add = 1,
}

export const Home = () => {
	const [assets, setAssets] = useState<AssetSummary[]>([]);
	const ep = useAssetsEndpoint();
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);

	useEffect(() => {
		DOMHelper.setPageTitle('Surplus');
		ep.SurplusedAssets().then(r => setAssets(r));
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} xs={12}>
				<AssetTable assets={assets} isLoading={ep.IsLoading} title="Surplused Assets" />
				<ProtectedComponent allowedRoles={['Admin']}>
					<Fab aria-label="add" onClick={() => setOpenModal(ModalOptions.Add)}>
						<AddIcon /> Surplus Asset
					</Fab>
				</ProtectedComponent>
				<Dialog
					open={openModal === ModalOptions.Add}
					onClose={() => setOpenModal(ModalOptions.None)}
					aria-labelledby="form-dialog-title"
					maxWidth="xs"
				>
					<DialogTitle id="form-dialog-title">Surplus Asset</DialogTitle>
					<DialogContent>
						<Grid container={true}>
							<Grid item={true} xs={12}>
								<SurplusAssetForm
									onSuccess={() => {
										setOpenModal(ModalOptions.None);
										ep.SurplusedAssets().then(r => setAssets(r));
									}}
								/>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</Grid>
		</Grid>
	);
};
