import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';
import { FundSource } from 'Models/FundSourceModels';

export const useFundSourcesEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('fundsources');

	const Get = () =>
		ep.Get<FundSource[]>().catch(() => {
			useError('Error getting fund sources');
			return [] as FundSource[];
		});

	return { Get, IsLoading: ep.IsLoading };
};
