import React, { useState } from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import classnames from 'classnames';

import * as AdminPages from 'Admin/Pages';
import * as AssetPages from 'Assets/Pages';
import * as CategoriesPages from 'Categories/Pages';
import * as EmployeePages from 'Employees/Pages';
import * as Pages from 'Common/Pages';
import * as SurplusPages from 'Surplus/Pages';
import * as TransferPages from 'Transfers/Pages';

import { makeStyles, ThemeProvider } from '@material-ui/styles';
import {
	Collapse,
	Theme,
	CssBaseline,
	Divider,
	Drawer,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Hidden,
	IconButton,
} from '@material-ui/core';
import {
	AccessAlarm as SurplusIcon,
	Add as AddTransferIcon,
	AddAlarm as AddSurplusIcon,
	AddToQueue as AddAssetIcon,
	ChevronLeft as ChevronLeftIcon,
	DesktopWindows as AssetIcon,
	KeyboardArrowDown as ArrowDownIcon,
	KeyboardArrowRight as ArrowRightIcon,
	Person as EmployeeIcon,
	PersonAdd as AddEmployeeIcon,
	PlaylistAdd as AddCategoryIcon,
	Repeat as TransferIcon,
	Search as SearchIcon,
	Settings as SettingsIcon,
} from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { AppBar } from 'Layout';
import { useAppState } from 'Context/AppProvider';
import { Sidebar } from 'Common/Elements/Sidebar';
import sidebarBg from 'Layout/Assets/img/isaac-munoz-H9r0_9bVv38-unsplash.jpg';
import { ProtectedComponent, ProtectedRoute } from 'Common/Utilities';
import { Skeleton } from '@material-ui/lab';
import { SignInManager } from 'Common/Auth';

interface ILayoutProps {
	isLoading: boolean;
}

const drawerWidth = 240;

const contentTheme = {
	props: {
		MuiFab: {
			variant: 'extended',
			color: 'primary',
		},
		MuiButton: {
			variant: 'contained',
			color: 'primary',
		},
	},
	overrides: {
		MuiPaper: {
			elevation1: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.14)',
			},
			elevation2: {
				padding: 8,
				margin: 8,
				boxShadow: '0 1px 6px 0 rgba(0, 0, 0, 0.14)',
			},
		},
		MuiExpansionPanelSummary: {
			root: {
				'&$expanded': {
					borderBottom: '1px solid rgba(0, 0, 0, .125)',
				},
			},
		},
		MuiTypography: {
			h5: {
				marginTop: 8,
				marginBottom: 8,
			},
		},
		MuiFab: {
			root: {
				margin: 0,
				top: 'auto',
				right: 20,
				bottom: 20,
				left: 'auto',
				position: 'fixed' as const,
			},
		},
		MuiFormControl: {
			root: {
				marginTop: 4,
				marginBottom: 4,
				minWidth: 175,
			},
		},
		MuiTableRow: {
			root: {
				'&:nth-child(even)': {
					backgroundColor: 'rgba(0,0,0,0.03)',
				},
				'&:empty': {
					display: 'none',
				},
			},
		},
		MuiTableCell: {
			body: {
				maxWidth: '300px',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
		},
		MuiMenuItem: {
			root: {
				minHeight: 32,
			},
		},
		MuiChip: {
			label: {
				userSelect: 'auto',
			},
		},
	},
};

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		height: '100%',
	},
	appBarSpacer: theme.mixins.toolbar,
	toolbarIconContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	toolbarIcon: {
		color: '#fff',
	},
	content: {
		flexGrow: 1,
		height: '100%',
		overflow: 'auto',
		padding: theme.spacing(3),
	},
	title: {
		textTransform: 'inherit',
		marginBottom: theme.spacing(2),
	},
	mobileTitle: {
		marginTop: theme.spacing(2),
	},
	pageLinks: {
		textDecoration: 'none',
	},
	navItem: {
		color: '#fff',
		margin: '4px',
		borderRadius: '3px',
		width: 'auto',
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.23)',
		},
	},
	navIconContainer: {
		color: '#fff',
		minWidth: '35px',
	},
	nestedNavItem: {
		paddingLeft: theme.spacing(4),
		'&:hover': {
			backgroundColor: 'rgba(255,255,255,0.23)',
		},
		'& > a': {
			borderBottom: '1px dotted #fff',
			color: '#fff',
			textDecoration: 'none',
		},
	},
	nestedIcon: {
		marginRight: 10,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		zIndex: 1000,
	},
	drawerPaper: {
		color: 'white',
		textTransform: 'uppercase',
		backgroundColor: 'rgba(0, 0, 0, .7)',
		backgroundImage: `url('${sidebarBg}')`,
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		padding: theme.spacing(2),
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9),
		},
	},
	sidebarDrawer: {
		'& > div': {
			position: 'static',
		},
	},
	navSkeleton: {
		backgroundColor: 'rgba(255,255,255,.2)',
		marginRight: 8,
		marginLeft: 8,
	},
}));

enum CollapseOptions {
	None = 0,
	Admin = 1,
}

const SkeletonBar = () => {
	const classes = useStyles();
	return (
		<Typography variant="h3">
			<Skeleton animation="wave" className={classes.navSkeleton} />
		</Typography>
	);
};

const NavContent = (props: { isLoading: boolean }) => {
	const classes = useStyles();

	const [openSection, setOpenSection] = useState(CollapseOptions.None);

	const toggleOpenSection = (clickedSection: CollapseOptions) => {
		// if the clicked item was already open, then close everything
		// otherwise show the clicked section
		setOpenSection(openSection === clickedSection ? CollapseOptions.None : clickedSection);
	};

	if (props.isLoading) {
		return (
			<>
				<SkeletonBar />
				<SkeletonBar />
				<SkeletonBar />
			</>
		);
	}

	return (
		<React.Fragment>
			<Typography variant="h5" component="h1" className={`${classes.title} ${classes.mobileTitle}`}>
				Asset Tracker
			</Typography>
			<Divider />
			<List>
				<Link to="/" className={classes.pageLinks}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon className={classes.navIconContainer}>
							<AssetIcon />
						</ListItemIcon>
						<ListItemText primary="Current Assets" />
					</ListItem>
				</Link>

				<Link to="/surplus" className={classes.pageLinks}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon className={classes.navIconContainer}>
							<SurplusIcon />
						</ListItemIcon>
						<ListItemText primary="Surplused Assets" />
					</ListItem>
				</Link>

				<Link to="/transfers" className={classes.pageLinks}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon className={classes.navIconContainer}>
							<TransferIcon />
						</ListItemIcon>
						<ListItemText primary="Transferred Assets" />
					</ListItem>
				</Link>

				<Link to="/employees" className={classes.pageLinks}>
					<ListItem button={true} className={classes.navItem}>
						<ListItemIcon className={classes.navIconContainer}>
							<EmployeeIcon />
						</ListItemIcon>
						<ListItemText primary="Employees" />
					</ListItem>
				</Link>

				<ProtectedComponent allowedRoles={['Admin']}>
					<List>
						<ListItem
							button={true}
							className={classes.navItem}
							onClick={() => toggleOpenSection(CollapseOptions.Admin)}
						>
							<ListItemIcon className={classes.navIconContainer}>
								<SettingsIcon />
							</ListItemIcon>
							<ListItemText primary="Admin" />
							{openSection === CollapseOptions.Admin ? <ArrowDownIcon /> : <ArrowRightIcon />}
						</ListItem>
						<Collapse in={openSection === CollapseOptions.Admin} timeout="auto" unmountOnExit={true}>
							<ListItem className={classes.nestedNavItem}>
								<AddAssetIcon className={classes.nestedIcon} />
								<Link to="/assets/add">Add Asset</Link>
							</ListItem>
							<ListItem className={classes.nestedNavItem}>
								<AddCategoryIcon className={classes.nestedIcon} />
								<Link to="/categories/add">Add Category</Link>
							</ListItem>
							<ListItem className={classes.nestedNavItem}>
								<AddEmployeeIcon className={classes.nestedIcon} />
								<Link to="/employees/add">Add Employee</Link>
							</ListItem>
							<ListItem className={classes.nestedNavItem}>
								<AddTransferIcon className={classes.nestedIcon} />
								<Link to="/transfers/add">Transfer Asset</Link>
							</ListItem>
							<ListItem className={classes.nestedNavItem}>
								<AddSurplusIcon className={classes.nestedIcon} />
								<Link to="/surplus/add">Surplus Asset</Link>
							</ListItem>
							<ListItem className={classes.nestedNavItem}>
								<SearchIcon className={classes.nestedIcon} />
								<Link to="/admin/search">Search Admin</Link>
							</ListItem>
						</Collapse>
					</List>
				</ProtectedComponent>
			</List>
		</React.Fragment>
	);
};

export const Layout: React.FunctionComponent<ILayoutProps & RouteComponentProps<any>> = props => {
	const classes = useStyles();
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const state = useAppState();

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar onMenuClick={() => setIsDrawerOpen(!isDrawerOpen)} />

			<Hidden mdDown={true} implementation="css">
				<Drawer
					variant="permanent"
					anchor="left"
					classes={{
						paper: classnames('page-links', classes.drawerPaper),
					}}
					open={true}
				>
					<NavContent isLoading={props.isLoading} />
				</Drawer>
			</Hidden>

			<Hidden smUp={true} implementation="css">
				<Drawer
					variant="temporary"
					anchor="left"
					ModalProps={{ onBackdropClick: () => setIsDrawerOpen(false) }}
					classes={{
						paper: classnames('page-links', classes.drawerPaper, !isDrawerOpen && classes.drawerPaperClose),
					}}
					open={isDrawerOpen}
				>
					<div className={classes.toolbarIconContainer}>
						<IconButton onClick={() => setIsDrawerOpen(false)}>
							<ChevronLeftIcon className={classes.toolbarIcon} />
						</IconButton>
					</div>
					<Divider />
					<NavContent isLoading={props.isLoading} />
				</Drawer>
			</Hidden>

			<ThemeProvider theme={overallTheme => ({ ...overallTheme, ...contentTheme })}>
				<main className={classnames(classes.content)}>
					<div className={classes.appBarSpacer} />
					<SignInManager>
						<Switch>
							<Route path="/" exact={true} component={AssetPages.Home} />
							<ProtectedRoute
								allowedRoles={['Admin']}
								path="/assets/add"
								exact={true}
								component={AssetPages.Add}
							/>
							<Route path="/assets/:id" component={AssetPages.Details} />
							<Route path="/search/:term" component={Pages.Search} />

							<Route path="/employees" exact={true} component={EmployeePages.Home} />
							<ProtectedRoute
								allowedRoles={['Admin']}
								path="/employees/add"
								exact={true}
								component={EmployeePages.Add}
							/>
							<Route path="/employees/:id" component={EmployeePages.Details} />

							<Route path="/surplus" exact={true} component={SurplusPages.Home} />
							<ProtectedRoute
								allowedRoles={['Admin']}
								path="/surplus/add/:id?"
								component={SurplusPages.Add}
							/>

							<Route path="/transfers" exact={true} component={TransferPages.Home} />
							<ProtectedRoute
								allowedRoles={['Admin']}
								path="/transfers/add/:id?"
								component={TransferPages.Add}
							/>

							<ProtectedRoute
								allowedRoles={['Admin']}
								path="/categories/add"
								exact={true}
								component={CategoriesPages.Add}
							/>

							<ProtectedRoute
								allowedRoles={['Admin']}
								path="/admin/search"
								exact={true}
								component={AdminPages.SearchAdmin}
							/>

							<Route path="/not-authorized" component={Pages.NotAuthorized} />
						</Switch>
					</SignInManager>
				</main>

				{state.SidebarContent && (
					<Hidden mdDown={true} implementation="css">
						<Drawer variant="permanent" anchor="right" className={classes.sidebarDrawer} open={true}>
							<Sidebar>
								<div className={classes.appBarSpacer} />
								{state.SidebarContent}
							</Sidebar>
						</Drawer>
					</Hidden>
				)}
			</ThemeProvider>
		</div>
	);
};

export default withRouter(Layout);
