export class AddTransfer {
	assetId = 0;
	transferReason = '';
	transferredTo = '';
	externalTransfer = true;
	transferDate: Date = new Date();

	constructor(assetId: number) {
		this.assetId = assetId;
	}
}
