import { EmployeeSummary } from 'Models/EmployeeModels';

export const sortByName = (a: EmployeeSummary, b: EmployeeSummary) => {
	const nameA = a.lastName.toUpperCase() + a.firstName.toUpperCase(); //ignore case
	const nameB = b.lastName.toUpperCase() + b.firstName.toUpperCase(); //ignore case
	if (nameA < nameB) {
		return -1;
	}
	if (nameA > nameB) {
		return 1;
	}
	return 0;
};
