import { AddTransfer } from 'Models/TransferModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useTransferEndpoint = () => {
	const { useError, useSuccess } = useNotifications();
	const ep = Endpoint('transfer');

	const Add = (dto: AddTransfer) =>
		ep
			.Post<number>(dto)
			.then(r => {
				useSuccess('Asset Transferred');
				return r;
			})
			.catch(() => {
				useError('Error Transferring asset');
				return undefined;
			});

	return { Add, IsLoading: ep.IsLoading };
};
