import { SchemaOf, object, number } from 'yup';
import { AssetModels, NoteModels } from 'Models';
import {
	selectSchema,
	stringSchema,
	dateSchema,
	emptyStringSchema,
	numberSchema,
	booleanSchema,
} from 'Common/Validation/BaseSchemas';
import { FundSourceValue } from 'Models/FundSourceModels';

export const AddAssetSchema: SchemaOf<AssetModels.AddAsset> = object({
	assetNumber: stringSchema('Asset Number'),
	name: stringSchema('Asset Name'),
	make: stringSchema('Make'),
	model: emptyStringSchema('Model'),
	serialNumber: stringSchema('Serial Number'),
	purchaseDate: dateSchema('Purchase Date'),
	vendor: stringSchema('Vendor'),
	categoryId: selectSchema('Category', 1),
	locationId: selectSchema('Location', 1),
	instituteId: selectSchema('Institute', 1),
	agrilifeAgencyId: number().nullable(),
	employeeId: selectSchema('Employee').notRequired(),
	locationDetails: emptyStringSchema('Location Details'),
	fundSourceId: selectSchema('Fund Source').nullable().notRequired(),
	accountNumber: emptyStringSchema('Account Number').when('fundSourceId', {
		is: (fundSourceId: FundSourceValue) => fundSourceId === FundSourceValue.SRS,
		then: stringSchema('Account Number'),
	}),
});

export const EditAssetSchema: SchemaOf<AssetModels.EditAsset> = object({
	id: numberSchema('Id'),
	statusId: selectSchema('Status'),
	assetNumber: stringSchema('Asset Number'),
	name: stringSchema('Name'),
	make: stringSchema('Make'),
	model: emptyStringSchema('Model'),
	serialNumber: stringSchema('Serial Number'),
	purchaseDate: dateSchema('Purchase Date'),
	vendor: stringSchema('Vendor'),
	categoryId: selectSchema('Category', 1),
	locationId: selectSchema('Location', 1),
	instituteId: selectSchema('Institute', 1),
	agrilifeAgencyId: number().nullable(),
	employeeId: selectSchema('Employee').notRequired(),
	isRentable: booleanSchema(),
	locationDetails: emptyStringSchema('Location Details'),
	fundSourceId: selectSchema('Fund Source').nullable().notRequired(),
	accountNumber: emptyStringSchema('Account Number').when('fundSourceId', {
		is: (fundSourceId: FundSourceValue) => fundSourceId === FundSourceValue.SRS,
		then: stringSchema('Account Number'),
	}),
});

export const AddNoteSchema: SchemaOf<NoteModels.AddNote> = object({
	text: stringSchema('Text'),
	assetId: numberSchema('Asset Id'),
});
