import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

interface ISidebarBoxProps {
	title: string;
	icon?: JSX.Element;
	children: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
	boxOuterContainer: {
		backgroundColor: theme.palette.type === 'light' ? 'white' : theme.palette.grey[700],
		margin: '5%',
		marginTop: '0',
		width: '90%',
		borderRadius: '5px',
	},
	boxInnerContainer: {
		borderBottom:
			'2px solid ' + (theme.palette.type === 'light' ? theme.palette.grey[100] : theme.palette.grey[800]),
		padding: '2%',
		flexWrap: 'wrap',
	},
	iconWrapper: { marginRight: '5px', height: '2em' },
	titleWrapper: {
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		display: 'inline-block',
	},
	childrenContainer: { padding: '5%' },
}));

export const SidebarBox = (props: ISidebarBoxProps) => {
	const classes = useStyles();
	return (
		<div className={classes.boxOuterContainer}>
			<div className={classes.boxInnerContainer}>
				{props.icon !== undefined && <span className={classes.iconWrapper}>{props.icon}</span>}
				<span className={classes.titleWrapper}>{props.title}</span>
			</div>
			<div className={classes.childrenContainer}>{props.children}</div>
		</div>
	);
};
