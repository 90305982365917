import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { Fade, Grid, Button, CircularProgress } from '@material-ui/core';
import { useSearchEndpoint } from 'Endpoints';
import { StyledCard } from 'Common/Elements';

export const SearchAdmin = () => {
	const [isBuilding, setIsBuilding] = useState(false);
	const ep = useSearchEndpoint();
	useEffect(() => {
		DOMHelper.setPageTitle('Search Admin');
	}, []);

	const buildIndex = () => {
		setIsBuilding(true);
		ep.BuildIndex().then(r => r && setIsBuilding(false));
	};
	return (
		<Fade in={true}>
			<Grid container={true}>
				<Grid item={true} md={6} lg={4}>
					<StyledCard title="Build Search Index">
						<p>
							Click the button below to rebuild the entire search index for assets, files and employees.
							This action may take a few minutes.
						</p>

						<Button variant="contained" color="primary" disabled={isBuilding} onClick={() => buildIndex()}>
							{isBuilding && (
								<CircularProgress
									size={24}
									style={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										marginTop: -12,
										marginLeft: -12,
									}}
								/>
							)}
							Rebuild Index
						</Button>
					</StyledCard>
				</Grid>
			</Grid>
		</Fade>
	);
};
