import React, { useEffect } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { Grid } from '@material-ui/core';
import { StyledCard } from 'Common/Elements';
import { TransferAssetForm } from 'Transfers/Forms';
import { RouteComponentProps, withRouter } from 'react-router-dom';

export const Add = (props: RouteComponentProps<any>) => {
	useEffect(() => {
		DOMHelper.setPageTitle('Transfer Asset');
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} lg={4}>
				<StyledCard title="Add Transfer">
					<TransferAssetForm onSuccess={() => props.history.push('/')} id={props.match.params.id} />
				</StyledCard>
			</Grid>
		</Grid>
	);
};

export default withRouter(Add);
