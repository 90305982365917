import React, { useEffect, useState } from 'react';
import { useTransferEndpoint, useAssetsEndpoint } from 'Endpoints';
import { AssetSummary } from 'Models/AssetModels';
import { AssetSelect, FormDebugging, GlobalDatePicker } from 'Common/FormFields';
import { TransferModels } from 'Models';
import { Formik, Form, Field } from 'formik';
import { FormControl, LinearProgress, FormControlLabel, Switch } from '@material-ui/core';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { TextField } from 'formik-material-ui';
import { AddTransferSchema } from './TransferSchemas';
import { EnhancedButton } from 'Common/Elements';

export interface ITransferAssetFormProps {
	onSuccess: () => void;
	id?: number;
}

export const TransferAssetForm = (props: ITransferAssetFormProps) => {
	const nameof = useNameof<TransferModels.AddTransfer>();
	const [assets, setAssets] = useState<AssetSummary[]>([]);
	// had to cast to string and parse to int because it was loading initial value as a string????
	const id = props.id ? parseInt(props.id.toString()) : 0;
	const [transfer, setTransfer] = useState(new TransferModels.AddTransfer(id));
	const [checked, setChecked] = useState(true);
	const assetsEp = useAssetsEndpoint();
	const ep = useTransferEndpoint();
	useEffect(() => {
		assetsEp.CurrentAssets().then(r => setAssets(r));
	}, []);

	const handleSuccess = () => {
		setTransfer(new TransferModels.AddTransfer(0));
		props.onSuccess();
	};

	return (
		<React.Fragment>
			<Formik
				initialValues={transfer}
				validationSchema={AddTransferSchema}
				onSubmit={(values, { setSubmitting }) => {
					ep.Add(values)
						.then(response => response && handleSuccess())
						.finally(() => setSubmitting(false));
				}}
			>
				{formprops => (
					<Form>
						<FormControl fullWidth={true}>
							<AssetSelect
								name={nameof('assetId')}
								assets={assets.filter(
									a => a.statusName !== 'Surplused' && a.statusName !== 'Transferred'
								)}
							/>
						</FormControl>
						<FormControl fullWidth={true}>
							<Field
								name={nameof('transferredTo')}
								type="text"
								label="Transfer To"
								component={TextField}
							/>
						</FormControl>
						<FormControlLabel
							control={
								<Field
									component={(p: any) => (
										<Switch
											name={nameof('externalTransfer')}
											checked={checked}
											onChange={value => {
												formprops.setFieldValue(
													nameof('externalTransfer'),
													value.target.checked
												);
												setChecked(value.target.checked);
											}}
										/>
									)}
								/>
							}
							label="External Transfer?"
						/>
						<FormControl fullWidth={true}>
							<Field
								name={nameof('transferReason')}
								type="text"
								label="Transfer Reason"
								component={TextField}
							/>
						</FormControl>
						<FormControl fullWidth={true}>
							<Field component={GlobalDatePicker} name={nameof('transferDate')} label="Transfer Date" />
						</FormControl>
						{formprops.isSubmitting && <LinearProgress />}
						<EnhancedButton
							submit={true}
							disabled={ep.IsLoading}
							isSubmitting={formprops.isSubmitting}
							action="add"
							label="Transfer"
						/>
						<FormDebugging />
					</Form>
				)}
			</Formik>
		</React.Fragment>
	);
};
