import React from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import { Institute } from 'Models/InstituteModels';

export interface IInstituteSelectProps {
	/**
	 * The property name of the containing model which this select controls.
	 * Suggest using the nameof generic (e.g. nameof<MyModel>('myProp')).
	 */
	name: string;
	/** The array of institutes that will be shown as dropdown items */
	institutes: Institute[];
	/** The label/placeholder for the select element */
	label?: string;
	/** Classes to be passed to the component */
}

/** Shows active agencies */
export const InstituteSelect = (props: IInstituteSelectProps) => {
	return (
		<Field
			component={TextField}
			name={props.name}
			select={true}
			label={props.label || 'Institute'}
			fullWidth={true}
		>
			<MenuItem key={`Institute-select-none`} value="0">
				- Select -
			</MenuItem>
			{props.institutes
				.filter(a => a.active)
				.map(a => {
					return (
						<MenuItem key={`institute-select-${a.id}`} value={a.id}>
							{a.name}
						</MenuItem>
					);
				})}
		</Field>
	);
};
