import { SchemaOf, object } from 'yup';
import { AddTransfer } from 'Models/TransferModels';
import { stringSchema, selectSchema, dateSchema, booleanSchema } from 'Common/Validation/BaseSchemas';

export const AddTransferSchema: SchemaOf<AddTransfer> = object({
	assetId: selectSchema('Current Assets', 1),
	transferReason: stringSchema('Transfer Reason'),
	transferredTo: stringSchema('Transferred To'),
	externalTransfer: booleanSchema(),
	transferDate: dateSchema('Transfer Date'),
});
