export class AssetCategory {
	id = 0;
	assetTypeId?: number = 0;
	name = '';
	active?: boolean;
}

export class AddAssetCategory {
	assetTypeId?: number = 0;
	name = '';
}
