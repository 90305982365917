import { Location } from 'Models/LocationModels';
import { useNotifications } from 'Common/Notifications';
import { Endpoint } from './Endpoint';

export const useLocationsEndpoint = () => {
	const { useError } = useNotifications();
	const ep = Endpoint('locations');

	const Get = () =>
		ep.Get<Location[]>().catch(() => {
			useError('Error getting locations');
			return [] as Location[];
		});
	return { Get, IsLoading: ep.IsLoading };
};
