import React, { useEffect } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { StyledCard } from 'Common/Elements';
import { Grid } from '@material-ui/core';
import { AddAssetForm } from 'Assets/Forms';

export const Add = () => {
	useEffect(() => {
		DOMHelper.setPageTitle('Add Asset');
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} lg={8}>
				<StyledCard title="Add Asset">
					<AddAssetForm />
				</StyledCard>
			</Grid>
		</Grid>
	);
};
