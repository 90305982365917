export enum FundSourceValue {
	None = 0,
	SRS = 1,
	UnitFunds = 2,
}

export class FundSource {
	id = 0;
	name = '';
	active = true;
}
