import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { EmployeeSummary } from 'Models/EmployeeModels';
import { TableStyles, TableIcons } from 'Common/Tables';
import { Link } from 'react-router-dom';
import MaterialTable from 'material-table';
import { useNameof } from 'Common/Helpers/ReactHelper';
import { LinearProgress, Fab } from '@material-ui/core';
import { useEmployeesEndpoint } from 'Endpoints';
import AddIcon from '@material-ui/icons/Add';
import { ProtectedComponent } from 'Common/Utilities';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { DeleteConfirmation } from 'Common/Modals';
import { sortByName } from 'Employees/Helpers';

enum ModalOptions {
	None = 0,
	DeleteEmployee = 1,
}

export const Home = () => {
	const [openModal, setOpenModal] = useState<ModalOptions>(ModalOptions.None);
	// to pass id value to delete confirmation modals
	const [modalValue, setModalValue] = useState(0);
	const [employees, setEmployees] = useState<EmployeeSummary[]>();
	const nameof = useNameof<EmployeeSummary>();
	const ep = useEmployeesEndpoint();

	const handleDeleteConfirm = (id: number) => {
		ep.Delete(id).then(r => {
			if (r) {
				employees && setEmployees(employees.filter(e => e.id != r));
				setOpenModal(ModalOptions.None);
				setModalValue(0);
			}
		});
	};

	useEffect(() => {
		DOMHelper.setPageTitle('Employees');
		ep.GetEmployees().then(r => setEmployees(r.sort(sortByName)));
	}, []);

	if (!employees) {
		return <LinearProgress />;
	}

	return (
		<>
			<MaterialTable
				style={{ marginBottom: 75 }} // make enough room for the fab to be below pagination controls
				columns={[
					{
						title: 'Name',
						field: nameof('name'),
						render: rowData => <Link to={'/employees/' + rowData.id}>{rowData.name}</Link>,
					},
					{ title: 'Institute', field: nameof('instituteName') },
				]}
				data={employees}
				title="Employees"
				icons={TableIcons}
				actions={[
					{
						icon: () => <DeleteOutline color="error" />,
						tooltip: 'Delete Employee',
						onClick: (event, rowData: EmployeeSummary) => {
							setModalValue(rowData.id);
							setOpenModal(ModalOptions.DeleteEmployee);
						},
					},
				]}
				options={{
					pageSize: 10,
					headerStyle: TableStyles.headerStyle,
					actionsColumnIndex: -1,
				}}
				isLoading={!employees.length}
			/>
			<ProtectedComponent allowedRoles={['Admin']}>
				<Link to="/employees/add">
					<Fab aria-label="add">
						<AddIcon /> Add Employee
					</Fab>
				</Link>
			</ProtectedComponent>

			<DeleteConfirmation
				isOpen={openModal === ModalOptions.DeleteEmployee}
				onCancel={() => setOpenModal(ModalOptions.None)}
				label="employee"
				onConfirm={() => {
					console.log(modalValue);
					handleDeleteConfirm(modalValue);
				}}
				additionalWarning="This will set any assets assigned to this employeee as 'In Service' to 'Not in Use' and remove the employee from any other assets assigned."
			/>
		</>
	);
};
