import { AssetSummary, AddAsset, AssetDetails, AssetType, EditAsset, Status } from 'Models/AssetModels';
import { Endpoint } from './Endpoint';
import { useNotifications } from 'Common/Notifications';

export const useAssetsEndpoint = () => {
	const { useSuccess, useError } = useNotifications();
	const ep = Endpoint('assets');

	const CurrentAssets = () =>
		ep.Get<AssetSummary[]>().catch(() => {
			useError('Error getting assets');
			return [] as AssetSummary[];
		});

	const Asset = (id: number) =>
		ep.Get<AssetDetails>(id).catch(() => {
			useError('Error getting asset');
			return undefined;
		});

	const Add = (dto: AddAsset) =>
		ep
			.Post<AssetSummary>(dto)
			.then(r => {
				useSuccess('Asset added');
				return r;
			})
			.catch(() => {
				useError('Error adding asset');
				return undefined;
			});

	const Edit = (id: number, dto: EditAsset) =>
		ep
			.Put<AssetDetails>(id, dto)
			.then(r => {
				useSuccess('Asset saved');
				return r;
			})
			.catch(() => {
				useError('Error saving asset');
				return undefined;
			});

	const Delete = (id: number) =>
		ep
			.Delete<number>(id)
			.then(r => {
				useSuccess('Asset deleted');
				return r;
			})
			.catch(() => {
				useError('Error deleting asset');
				return undefined;
			});

	const SurplusedAssets = () =>
		ep.Get<AssetSummary[]>('surplused').catch(() => {
			useError('Error getting surplused assets');
			return [];
		});

	const TransferredAssets = () =>
		ep.Get<AssetSummary[]>('transferred').catch(() => {
			useError('Error getting transferred assets');
			return [];
		});

	const Types = () =>
		ep.Get<AssetType[]>('types').catch(() => {
			useError('Error getting types');
			return [];
		});

	const Statuses = () =>
		ep.Get<Status[]>('statuses').catch(() => {
			useError('Error getting statuses');
			return [];
		});

	return {
		CurrentAssets,
		Asset,
		Add,
		Edit,
		Delete,
		SurplusedAssets,
		TransferredAssets,
		Types,
		Statuses,
		IsLoading: ep.IsLoading,
	};
};
