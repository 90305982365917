import { Note } from './NoteModels';
import { File } from './FileModels';
import { FundSourceValue } from './FundSourceModels';

export class AssetSummary {
	id = 0;
	name = '';
	assetNumber = '';
	categoryName = '';
	typeName = '';
	locationName = '';
	instituteName = '';
	statusName = '';
	employeeName = '';
	employeeId = 0;
}

export class AssetDetails {
	id = 0;
	name = '';
	assetNumber = '';
	instituteId = 0;
	instituteShortName = '';
	agrilifeAgencyId?: number;
	agrilifeAgencyName = '';
	make = '';
	model = '';
	statusId = 0;
	statusName: StatusValue = StatusValue.None;
	categoryId = 0;
	categoryName = '';
	serialNumber = '';
	vendor = '';
	purchaseDate: Date = new Date();
	locationId = 0;
	locationName = '';
	locationDetails = '';
	employeeId?: number;
	employeeName = '';
	fundSourceId: Nullable<FundSourceValue> = FundSourceValue.None;
	fundSourceName = '';
	accountNumber = '';
	isRentable = false;
	isRented = false;
	surplusedOn?: Date;
	surplusReason?: string;
	surplusCreatedBy?: string;
	transferredOn?: Date;
	transferReason?: string;
	transferredTo?: string;
	transferCreatedBy?: string;
	rentalReason?: string;
	rentedOn?: Date;
	returnOn?: Date;
	rentedByName?: string;
	submittedAt: Date = new Date();
	createdAt: Date = new Date();
	createdBy = '';
	updatedAt?: Date;
	updatedBy = '';
	files: File[] = [];
	notes: Note[] = [];
}

export class AddAsset {
	assetNumber = '';
	name = '';
	make = '';
	model = '';
	serialNumber = '';
	purchaseDate: Date = new Date();
	vendor = '';
	categoryId = 0;
	locationId = 0;
	instituteId = 0;
	agrilifeAgencyId?: number | null;
	employeeId?: number;
	locationDetails = '';
	fundSourceId?: Nullable<FundSourceValue> = FundSourceValue.None;
	accountNumber?: string = '';
}

export class EditAsset {
	id = 0;
	statusId = 0;
	assetNumber = '';
	name = '';
	make = '';
	model = '';
	serialNumber = '';
	purchaseDate: Date = new Date();
	vendor = '';
	categoryId = 0;
	locationId = 0;
	instituteId = 0;
	agrilifeAgencyId?: number | null;
	employeeId?: number;
	isRentable = false;
	locationDetails = '';
	fundSourceId?: Nullable<FundSourceValue> = FundSourceValue.None;
	accountNumber = '';

	constructor(details: AssetDetails) {
		this.id = details.id;
		this.statusId = details.statusId;
		this.assetNumber = details.assetNumber;
		this.name = details.name;
		this.make = details.make;
		this.model = details.model;
		this.serialNumber = details.serialNumber;
		this.purchaseDate = details.purchaseDate;
		this.vendor = details.vendor;
		this.categoryId = details.categoryId;
		this.locationId = details.locationId;
		this.instituteId = details.instituteId;
		this.agrilifeAgencyId = details.agrilifeAgencyId;
		this.employeeId = details.employeeId;
		this.isRentable = details.isRentable;
		this.locationDetails = details.locationDetails;
		this.fundSourceId = details.fundSourceId;
		this.accountNumber = details.accountNumber;
	}
}

export class AssetType {
	id = 0;
	name = '';
	active?: boolean;
}

export enum StatusValue {
	None = '',
	InService = 'In Service',
	NotUsed = 'Not Being Used/In Storage',
	Repair = 'Out for Repair/Maintenance',
	OnRental = 'On Rental',
	Lost = 'Lost',
	Broken = 'Broken',
	Surplused = 'Surplused',
	Transferred = 'Transferred',
}

export type StatusNames =
	| 'In Service'
	| 'Not Being Used/In Storage'
	| 'Out for Repair/Maintenance'
	| 'On Rental'
	| 'Lost'
	| 'Broken'
	| 'Surplused'
	| 'Transferred';

export class Status {
	id = 0;
	name: StatusNames = 'In Service';
	active = true;
}
