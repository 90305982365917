import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

export interface IRentalsTabContentProps {
	assetId: number;
	isRentable: boolean;
	isRented: boolean;
}

const useStyles = makeStyles(theme => ({
	notRentableContainer: {
		backgroundColor: theme.palette.action.disabledBackground,
		borderRadius: '3px',
		color: theme.palette.text.disabled,
		display: 'flex',
		fontSize: '18px',
		fontWeight: 500,
		justifyContent: 'center',
		marginTop: theme.spacing(1),
		padding: theme.spacing(2),
	},
}));

export const RentalsTabContent = (props: IRentalsTabContentProps) => {
	const classes = useStyles();

	if (!props.isRentable || props.isRented) {
		return (
			<Grid container={true} className={classes.notRentableContainer}>
				This asset is not currently rentable.
			</Grid>
		);
	}

	return <React.Fragment>Rental form will go here</React.Fragment>;
};
