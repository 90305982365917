import moment from 'moment';

export const relativeTime = (dateTime: Date): string => {
	// if date is less than a week old, show the relative time
	if (moment(dateTime) > moment().subtract(7, 'd')) {
		return moment(dateTime).fromNow();
	} else {
		// otherwise show the actual date and time
		return moment(dateTime).format('MMMM Do, YYYY h:mm a');
	}
};

export const afterToday = (dateTime: Date): boolean => {
	return moment(dateTime).isAfter(moment(new Date()));
};