import React, { useEffect, useState } from 'react';
import { DOMHelper } from 'Common/Helpers';
import { AssetSummary } from 'Models/AssetModels';
import { useAssetsEndpoint } from 'Endpoints';
import { Grid, Fab } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AssetTable } from 'Common/Tables';
import AddIcon from '@material-ui/icons/Add';
import { ProtectedComponent } from 'Common/Utilities';

export const Home = () => {
	const [assets, setAssets] = useState<AssetSummary[]>([]);
	const ep = useAssetsEndpoint();

	useEffect(() => {
		DOMHelper.setPageTitle('Current Assets');
		ep.CurrentAssets().then(r => setAssets(r));
	}, []);

	return (
		<Grid container={true}>
			<Grid item={true} xs={12}>
				<AssetTable assets={assets} isLoading={ep.IsLoading} />
				<ProtectedComponent allowedRoles={['Admin']}>
					<Link to="/assets/add">
						<Fab aria-label="add">
							<AddIcon /> Add Asset
						</Fab>
					</Link>
				</ProtectedComponent>
			</Grid>
		</Grid>
	);
};
